import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';

function* getFieldSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getField.try,
        constants.getField.success,
        constants.getField.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.getField, payload);
        console.log(dataRaw)
        const data = converter._server2Client(dataRaw?.data);
        console.log(data)
        yield put(actions.getFieldShape(`?filter={"vega_key":"${data.vega_key}"}`));
        yield put(actions.getFieldForecast(data.vega_key));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* calculatePestProbabilitySaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.calculatePestProbability.try,
        constants.calculatePestProbability.success,
        constants.calculatePestProbability.error
    );
    try {
        yield put(start());
        const { id } = payload;
        yield call(api.calculatePestProbability, payload);
        yield put(actions.getFieldForecast(id));
        yield put(success());
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getField.toString(),
            getFieldSaga
        ),
        yield takeLatest(
            constants.calculatePestProbability.toString(),
            calculatePestProbabilitySaga
        ),
        yield takeLatest(
            constants.getFields.toString(),
            defaultSaga({
                constants: constants.getFields,
                api: api.getFields
            })
        ),
        yield takeLatest(
            constants.getFieldShape.toString(),
            defaultSaga({
                constants: constants.getFieldShape,
                api: api.getFieldShape
            })
        ),
        yield takeLatest(
            constants.getFieldsShape.toString(),
            defaultSaga({
                constants: constants.getFieldsShape,
                api: api.getFieldsShape
            })
        ),
        yield takeLatest(
            constants.getFieldForecast.toString(),
            defaultSaga({
                constants: constants.getFieldForecast,
                api: api.getFieldForecast
            })
        ),
        yield takeLatest(
            constants.updateFieldForecast.toString(),
            defaultSaga({
                constants: constants.updateFieldForecast,
                api: api.updateFieldForecast
            })
        ),
        yield takeLatest(
            constants.getPestProbabilityData.toString(),
            defaultSaga({
                constants: constants.getPestProbabilityData,
                api: api.getPestProbabilityData
            })
        )
    ]);
}