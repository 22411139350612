import * as constants from './constants';

export const getFields = payload => ({
    type: constants.getFields.toString(),
    payload
});

export const setField = payload => ({
    type: constants.setField,
    payload
});

export const getField = payload => ({
    type: constants.getField.toString(),
    payload
});

export const getFieldShape = payload => ({
    type: constants.getFieldShape.toString(),
    payload
});

export const getFieldsShape = payload => ({
    type: constants.getFieldsShape.toString(),
    payload
});

export const getFieldForecast = payload => ({
    type: constants.getFieldForecast.toString(),
    payload
});

export const updateFieldForecast = payload => ({
    type: constants.updateFieldForecast.toString(),
    payload
});

export const getPestProbabilityData = payload => ({
    type: constants.getPestProbabilityData.toString(),
    payload
});

export const calculatePestProbability = payload => ({
    type: constants.calculatePestProbability.toString(),
    payload
});

export const clearField = payload => ({
    type: constants.clearField.toString()
});
