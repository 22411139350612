import React from 'react';
import { Redirect, Switch } from 'react-router';
import * as Pages from './pages';

export default (
    <Switch>
        <Redirect from="//*" to="/*" />
        { Object.values(Pages) }
        <Redirect
            exact
            from="/"
            key="default"
            to="/" />

        {/* todo 404 */}
        {/* <Redirect */}
        {/*    key="default" */}
        {/*    to="/home" /> */}
    </Switch>
);
