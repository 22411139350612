import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteMatch, useHistory } from 'react-router-dom';
import {
    Row, Col, Form, Input, Button
} from 'antd';

import { useClassName } from 'utils/cn';

import { selectors, actions } from '../../models';

const { addCalculatorMyco } = actions;
const { getCurMyco } = selectors;

export default () => {
    const cn = useClassName('myco');
    const { t } = useTranslation('myco');
    const dispatch = useDispatch();
    const calculator = useSelector(state => getCurMyco(state))

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ name: calculator.name })
    }, [calculator])

    const onFinish = (values) => {
        values.user = 1;
        dispatch(addCalculatorMyco({ values }));
    };

    return (
        <Form onFinish={onFinish}
            form={form}
            className={cn('data')}
            name="calculator-form">
            <div>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className="form-field">
                            <div className="form-label">
                                {t('calculator title')}
                            </div>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Input />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
            {!calculator.id && (
                <Row>
                    <Col span={24} className={cn('button-block')}>
                        <Button type="primary" htmlType="submit" size="large">
                            {t('start')}
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    );
};