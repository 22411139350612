import options from './options';
import axiosInstance from '../../utils/axios';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getRegions = (filter = '') => axiosInstance.get(
    urlPath(filter)
);

export const getRegion = id => axiosInstance.get(
    urlPath(`${id}/`)
);