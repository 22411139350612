import {
    login, refresh, userActivation, resetPassword, resetPasswordConfirm
} from '../constants';

import { isValid } from '../../../utils/utils';

const initialState = localStorage.getItem('token');
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case login.success: {
            localStorage.setItem('token', action.payload?.data.access);
            localStorage.setItem('refresh', action.payload?.data.refresh);
            return action.payload?.data.access;
        }
        case refresh.success: {
            localStorage.setItem('token', action.payload?.data.access);
            localStorage.setItem('refresh', action.payload?.data.refresh);
            return action.payload?.data.access;
        }
        case refresh.error: {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            return false;
        }
        case login.try: {
            localStorage.setItem('token', '');
            localStorage.setItem('refresh', '');
            return false;
        }
        case 'LOGOUT':
        case userActivation.toString():
        case resetPasswordConfirm.toString():
        case resetPassword.toString(): {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            return false; }
        default:
            return state;
    }
}
