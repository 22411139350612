import * as constants from '../constants';
import converter from '../converter';

const initialState = converter._server2Client({});

const initialState1 = {
    id: 116,
    preceding_culture: {
        id: 1,
        name: 'Пшеница',
        color: '#aa1976'
    },
    culture: {
        id: 1,
        name: 'Пшеница',
        color: '#aa1976'
    },
    name: 'Калькулятор',
    creation_date: '2022-06-09T09:04:03.754065+03:00',
    is_done: false,
    sampling_coordinates: {
        type: 'Point',
        coordinates: []
    },
    probe_number: null,
    cultures_comment: 'cultures_match',
    interpretation: {
        neutral: {
            koe: 1,
            percentage: 1.098901098901099,
            micromycete: [
                {
                    koe: 1,
                    ref: 11,
                    percentage: 1.098901098901099
                }
            ]
        },
        total_koe: 91,
        conclusion: 'phytopathogenic__very_high-suppressive__very_low',
        suppressive: {
            koe: 10,
            percentage: 10.989010989010989,
            micromycete: [
                {
                    koe: 10,
                    ref: 8,
                    percentage: 10.989010989010989
                }
            ],
            interpretation: 'very_low'
        },
        phytopathogenic: {
            koe: 80,
            percentage: 87.91208791208791,
            micromycete: [
                {
                    koe: 80,
                    ref: 1,
                    losses: 0.8791208791208791,
                    percentage: 87.91208791208791
                }
            ],
            interpretation: 'very_high'
        },
        total_percentage: 100
    },
    user: 1
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getCalculatorMyco.success:
        case constants.setParamsCalculatorMyco.success:
        case constants.addCalculatorMyco.success: {
            return action.payload;
        }

        case constants.closeCalculatorMyco.success: {
            const { is_done } = action.payload;
            return { ...state, is_done };
        }

        case constants.clearCalculatorMyco.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
