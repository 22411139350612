import options from './options';
import axiosInstance from '../../utils/axios';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getCultures = (filter = '') => axiosInstance.get(
    urlPath(filter)
);

export const getCulture = id => axiosInstance.get(
    urlPath(`${id}/`)
);