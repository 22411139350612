import React, { useEffect, useState } from 'react';
import {
    Layout, Row, Col, Tabs, Table, Card, Collapse, Checkbox, Radio
} from 'antd';

import './style.less';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InfoCircleFilled } from '@ant-design/icons';
import Map from './map';
import { getUser } from '../../models/account/selectors';
import { getFields, setField, getFieldsShape } from '../../models/fields/actions';
import {
    getCurPhases, getCurShapes, getFieldsList
} from '../../models/fields/selectors';
import { setSelectedMenu, setCheckedFields, setSelectedField } from '../../reducers/view/actions';
import { selectedMenu } from '../../reducers/view/selectors';
import { useClassName } from '../../utils/cn';

import Analysis from '../../components/analysis'
import Forecast from '../../components/forecast'
import { FieldsCard } from './card'

const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const App = () => {
    const { t } = useTranslation('field');
    const cn = useClassName('page');

    const dispatch = useDispatch();
    const user = useSelector(state => getUser(state));
    const fields = useSelector(state => getFieldsList(state))
    const fieldsShape = useSelector(state => getCurShapes(state))
    const selMenu = useSelector(state => selectedMenu(state))
    const forecastPhases = useSelector(state => getCurPhases(state))

    const [selectedFields, setSelectedFields] = useState([])

    useEffect(() => {
        dispatch(getFields(`?filter={"user":${user.id}}&page_size=1000`))
        dispatch(getFieldsShape())
    }, []);

    const setFieldNumber = (id) => {

    }

    const onChangeTab = (key) => {
        dispatch(setSelectedMenu(key))
    };

    const onChangeTabFields = (key) => {

    }

    const onCheckboxChange = (values) => {
        const selectedFields = fields.filter(item => values.includes(item.vega_key))
        dispatch(setCheckedFields([...values]))
        setSelectedFields([...selectedFields])
    }

    const onRadioChange = ({ target }) => {
        const selectedField = fields.find(item => item.vega_key === target.value)
        dispatch(setSelectedField(target.value))
        dispatch(setField(selectedField))
    }

    const columnsCheckbox = [
        {
            title: '',
            dataIndex: 'action',
            render: (value, record) => (
                <Checkbox value={record.id} />
            )
        },
        {
            title: t('field number'),
            dataIndex: 'id',
            render: (value, record) => (
                <div onClick={() => setFieldNumber(record.id)}>
                    {value}
                </div>
            )
        },
        {
            title: t('culture'),
            dataIndex: 'culture',
            sorter: (a, b) => b?.culture?.name?.localeCompare(a?.culture?.name),
            render: value => (
                <>
                    {value?.name}
                </>
            )
        }
    ];

    const columnsRadio = [
        {
            title: '',
            dataIndex: 'action',
            render: (value, record) => (
                <Radio value={record.id} />
            )
        },
        {
            title: t('field number'),
            dataIndex: 'id',
            render: (value, record) => (
                <div onClick={() => setFieldNumber(record.id)}>
                    {value}
                </div>
            )
        },
        {
            title: t('culture'),
            dataIndex: 'culture',
            sorter: (a, b) => b?.culture?.name?.localeCompare(a?.culture?.name),
            render: value => (
                <>
                    {value?.name}
                </>
            )
        }
    ];

    return (

        <Content className={cn()}>
            <Row gutter={30}>
                <Col span={17}>
                    <Tabs activeKey={selMenu} onChange={onChangeTab} className={cn('main-tabs')}>
                        <TabPane tab={t('current')} key="current">
                            <div id="map">
                                <Map />
                            </div>
                        </TabPane>
                        <TabPane tab={t('forecast')} key="forecast" className={cn('tabpane')}>
                            <Collapse className={cn('main-collapse')}>
                                <Panel header={t('forecast ho')} key="2">
                                    {forecastPhases?.vegetation_phases?.length > 0 ? <Forecast /> : (
                                        <div className="info-description">
                                            <InfoCircleFilled />
                                            {t('choose field')}
                                        </div>
                                    )}
                                </Panel>
                            </Collapse>
                        </TabPane>
                        <TabPane tab={t('instruments')} key="instruments" className={cn('tabpane')}>
                            <Collapse className={cn('main-collapse')}>
                                <Panel header={t('myco analysis')} key="1">
                                    <Analysis />
                                </Panel>
                            </Collapse>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span={7}>
                    {selectedFields.length > 0 && (
                        <FieldsCard selectedFields={selectedFields}
                            t={t}
                            cn={cn}
                            onChangeTabFields={onChangeTabFields} />
                    )}
                    {
                        selMenu === 'current' && (
                            <Card title={t('all fields')}
                                bordered={false}>
                                <Checkbox.Group onChange={onCheckboxChange}>
                                    <Table dataSource={fieldsShape}
                                        columns={columnsCheckbox}
                                        pagination={false} />
                                </Checkbox.Group>
                            </Card>
                        )
                    }
                    {
                        selMenu !== 'current' && (
                            <Card title={t('all fields')}
                                bordered={false}>
                                <Radio.Group onChange={onRadioChange}>
                                    <Table dataSource={fieldsShape}
                                        columns={columnsRadio}
                                        pagination={false} />
                                </Radio.Group>
                            </Card>
                        )
                    }

                </Col>
            </Row>
        </Content>

    );
};

export default App;