import React, { Component } from 'react';
import {
    Input, Form, Button, Row, Col
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons'

import { useClassName } from 'utils/cn';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from '../../../models';

const LOGIN_FORM = 'login_form';

const { login } = actions;

const { isError } = selectors

export default ({ onClose }) => {
    const cn = useClassName('login');
    const { t } = useTranslation('login');
    const dispatch = useDispatch();

    const isLoginError = useSelector(state => isError(state))

    const onSubmit = (values) => {
        dispatch(login(values));
    };
    return (
        <Form
            name={LOGIN_FORM}
            className={cn()}
            onFinish={onSubmit}>
            <Row gutter={20} justify="space-around">
                <Col className={cn('title')}>{t('title')}</Col>
            </Row>
            <div className={cn('inner')}>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className="form-field">
                            <div className="form-label">
                                {t('login')}
                            </div>
                            <Form.Item name="username"
                                rules={[
                                    { required: true, message: t('not empty') }
                                ]}>
                                <Input
                                    placeholder={t('login placeholder')}
                                    maxLength={255} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className="form-field">
                            <div className="form-label">
                                {t('password')}
                            </div>
                            <Form.Item name="password"
                                rules={[
                                    { required: true, message: t('not empty') }
                                ]}>
                                <Input
                                    type="password"
                                    placeholder={t('password placeholder')}
                                    maxLength={255} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row guter={20} justify="space-around">
                    <Col span={24}>
                        <div className={cn('error')}>
                            {isLoginError && (
                                <div className={cn('error-inner')}>
                                    <ExclamationCircleFilled />
                                    {t('error')}
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={24}>
                        <Button
                            size="large"
                            htmlType="submit"
                            type="primary">
                            {t('submit')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Form>

    );
};