import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import { Layout } from 'antd';
import { isAuth } from '../../models/account/selectors';
import Header from '../header';

const mstp = state => ({
    isAuth: isAuth(state)
});

const mdtp = ({});

@connect(mstp, mdtp)
export default class Private extends Component {
    render() {
        const {
            redirectPath, isAuth, component: Component, ...rest
        } = this.props;

        return (
            <Route {...rest}
                render={(props) => (
                    <Layout>
                        <Header />
                        <Component {...props} />
                    </Layout>
                )} />
        );
    }
}

Private.defaultProps = {
    redirectPath: '/'
};

Private.propTypes = {
    redirectPath: PropTypes.string
};
