import * as constants from './constants';

export const getRegions = payload => ({
    type: constants.getRegions.toString(),
    payload
});

export const getRegion = payload => ({
    type: constants.getRegion.toString(),
    payload
});
