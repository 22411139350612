import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { connect } from 'react-redux';

import { logout } from '../../models/account/actions';
import cn from '../../utils/cn';

import './style.less';

const { Content } = Layout;


const { SubMenu } = Menu;
const mstp = (state) => ({});

const mdtp = ({
    logout
});

@connect(mstp, mdtp)
export default class Error extends Component {
    componentDidMount() {
        const { logout } = this.props;
        logout();
    }

    render() {
        return (
            <Layout className={cn()}>
                <Content className={cn('content')} />
            </Layout>
        );
    }
}
