import axiosInstance from '../../utils/axios';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const login = body => axiosInstance.post(
    urlPath('jwt/create/'),
    body
);

export const register = body => axiosInstance.post(
    urlPath('users/'),
    body
);

export const verify = body => axiosInstance.post(
    urlPath('jwt/verify/'),
    body
);

export const refresh = body => axiosInstance.post(
    urlPath('jwt/refresh/'),
    body
);

export const userActivation = body => axiosInstance.post(
    urlPath('users/activation/'),
    body
);

export const resetPassword = body => axiosInstance.post(
    urlPath('/users/reset_password/'),
    body
);

export const resetPasswordConfirm = body => axiosInstance.post(
    urlPath('/users/reset_password_confirm/'),
    body
);


export const getProfile = body => axiosInstance.get(
    urlPath('users/me/')
);

export const getProfileInfo = body => axiosInstance.get(
    urlPath('users/me/')
);

export const updateProfile = body => axiosInstance.put(
    urlPath('users/me/'),
    body
);