import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    _server2Client = (item = {}) => ({
        id: item.id,
        preceding_culture: item.preceding_culture || {},
        culture: item.culture || {},
        name: item.name,
        creation_date: item.creation_date,
        is_done: item.is_done,
        sampling_coordinates: item.sampling_coordinates || { type: 'Point', coordinates: [] },
        probe_number: item.probe_number,
        cultures_comment: item.cultures_comment,
        interpretation: this.interpretation2Client(item.interpretation || {}),
        user: 1
    });

    interpretation2Client = (item = {}) => ({
        neutral: this.micromycete2Client(item.neutral || {}),
        total_koe: item.total_koe,
        conclusion: item.conclusion,
        suppressive: this.micromycete2Client(item.suppressive || {}),
        phytopathogenic: this.micromycete2Client(item.phytopathogenic || {}),
        total_percentage: item.total_percentage || 0

    });

    micromycete2Client = (item = {}) => (
        {
            koe: item.koe,
            percentage: item.percentage || 0,
            micromycete: item.micromycete || [],
            interpretation: item.interpretation
        }
    )
}

export default new Converter();
