import options from './options';
import axiosInstance from '../../utils/axios';

const urlPath = (url, path) => `${url}${path}`;

export const getCalculatorsMyco = filter => axiosInstance.get(
    urlPath(options.apiUrl, filter)
);

export const getCalculatorMyco = id => axiosInstance.get(
    urlPath(options.apiUrl, `${id}/`)
);

export const addCalculatorMyco = body => axiosInstance.post(
    urlPath(options.apiUrl, 'create_calculator/'),
    body
);

export const setParamsCalculatorMyco = body => axiosInstance.post(
    urlPath(options.apiUrl, `${body.id}/set_parameters/`),
    body.values
);

export const closeCalculatorMyco = body => axiosInstance.post(
    urlPath(options.apiUrl, `${body.id}/close/`),
    body.values
);

export const removeCalculatorMyco = id => axiosInstance.remove(
    urlPath(options.apiUrl, `${id}/`)
);

export const getCultureCalculatorsMyco = id => axiosInstance.get(
    urlPath(options.apiUrl, `${id}/`)
);


export const getPrecedingCalculatorsMyco = id => axiosInstance.get(
    urlPath(options.apiUrl, `${id}/`)
);


export const getGenusOfMicroCalculatorsMyco = filter => axiosInstance.get(
    urlPath(options.apiGenusUrl, filter)
);

export const getGenusOfMicroCalculatorsMycoItem = id => axiosInstance.get(
    urlPath(options.apiGenusUrl, `${id}/`)
);


export const getTypeOfResearchCalculatorsMyco = filter => axiosInstance.get(
    urlPath(options.apiMycoUrl, filter)
);

export const getTypeOfResearchCalculatorsMycoItem = id => axiosInstance.get(
    urlPath(options.apiMycoUrl, `${id}/`)
);
