import {
    getProfile
} from '../constants';

const initialState = {
    id: null,
    username: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case getProfile.success: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}