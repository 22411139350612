import { getConstant } from '../utils';

export const login = getConstant('LOGIN');
export const logout = getConstant('LOGOUT');
export const verify = getConstant('VERIFY');
export const refresh = getConstant('REFRESH');
export const getProfile = getConstant('GET_PROFILE');
export const getProfileInfo = getConstant('GET_PROFILE_INFO');
export const updateProfile = getConstant('UPDATE_PROFILE');
export const register = getConstant('REGISTRATION');

export const userActivation = getConstant('USER_ACTIVATION');
export const resetPassword = getConstant('RESET_PASSWORD');
export const resetPasswordConfirm = getConstant('RESET_PASSWORD_CONFIRM');
