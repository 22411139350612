import React from 'react';

import moment from 'moment';

export const options = {
    responsive: true,
    scales: {
        y: {
            ticks: {
                type: 'linear',
                position: 'left',
                display: false,
                autoSkip: false,
                min: moment('2021-06-12').valueOf(),
                max: moment('2021-08-12').valueOf(),
                stepSize: 1000000000,
                callback: function (value, index, ticks) { // eslint-disable-line
                    const date = moment(value);
                    return date.format('YYYY-MM-DD');
                }
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false
        },
        tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,

            external: function(context) { //eslint-disable-line
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = '<thead>';

                    titleLines.forEach((title) => {
                        innerHtml += `<tr><th>${title}</th></tr>`;
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach((body, i) => {
                        const bodyVal = moment(Number(body[0].replace(/\s+/g, ''))).format('YYYY-MM-DD')
                        const colors = tooltipModel.labelColors[i];
                        let style = `background:${colors.backgroundColor}`;
                        style += `; border-color:${colors.borderColor}`;
                        style += '; border-width: 2px';
                        const span = `<span style="${style}"></span>`;
                        innerHtml += `<tr><td>${span}${bodyVal}</td></tr>`;
                    });
                    innerHtml += '</tbody>';

                    const tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                const position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
                tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
                tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
                tooltipEl.style.pointerEvents = 'none';
            }
        }
    }
};