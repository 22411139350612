import React from 'react';
import { Route } from 'react-router';

import Page from './component';
import PrivateRoute from '../../containers/private-route';

export default <PrivateRoute
    exact
    key="home"
    path="/fields"
    component={Page} />;