import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const getsMycoBody = state => state.models[name].calculators;
export const getMycoList = state => state.models[name].calculators.results;
export const getMycoCount = state => state.models[name].calculators.count;
export const getCurMyco = state => state.models[name].calculator;
export const isMycoLoading = state => state.models[name].loading;
export const isMycoComplete = state => state.models[name].calculator?.is_done;

export const getCultureMyco = state => state.models[name].culture;
export const getPrecedingMyco = state => state.models[name].preceding;
export const getGenusOfMicroCatalogCalculatorsMyco = state => state.models[name].genusOfMicro;
export const getTypeOfResearchCalculatorsMyco = state => state.models[name].typeOfResearch;