import { login, getProfile, verify } from '../constants';

const initialState = {
    isAuth: false,
    isError: false
}

export default function reducer(state = false, action) {
    switch (action.type) {
        case getProfile.success:
        case verify.success: {
            return { isAuth: true };
        }

        case login.success: {
            return { isAuth: true, isError: false };
        }

        case 'LOGOUT':
        case getProfile.error:
        case getProfile.try:
        case login.try: {
            return { isAuth: false };
        }

        case login.error: {
            return { isAuth: false, isError: true };
        }

        default: return state;
    }
}
