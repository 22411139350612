import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { getUser, getUserId } from '../../models/account/selectors';
import { getProfile } from '../../models/account/actions';

export default ({ children }) => {
    const isAuth = useSelector(state => getUserId(state));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfile());
    }, []);


    return (
        <>
            {(!isAuth && location.pathname !== '/') ? <Spin /> : children}
        </>
    );
};