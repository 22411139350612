import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    seasonsToClient = (rawObject = {}) => {
        const seasonsN = rawObject.filter(item => item.fertilizerType === 'Азот');
        const seasonsP = rawObject.filter(item => item.fertilizerType === 'Фосфор');
        const seasonsK = rawObject.filter(item => item.fertilizerType === 'Калий');

        const objN = {};
        const objP = {};
        const objK = {};

        seasonsN.forEach(item => {
            objN[item.combination] = {
                autumnValue: item.mainFromAutumn.includes('-') ? item.mainFromAutumn.split('-') : item.mainFromAutumn,
                springValue: item.sowingInSpring.includes('-') ? item.sowingInSpring.split('-') : item.sowingInSpring,
                firstValue: item.firstFeeding.includes('-') ? item.firstFeeding.split('-') : item.firstFeeding,
                secondValue: item.secondFeeding.includes('-') ? item.secondFeeding.split('-') : item.secondFeeding
            };
        });
        seasonsP.forEach(item => {
            objP[item.combination] = {
                autumnValue: item.mainFromAutumn.includes('-') ? item.mainFromAutumn.split('-') : item.mainFromAutumn,
                springValue: item.sowingInSpring.includes('-') ? item.sowingInSpring.split('-') : item.sowingInSpring,
                firstValue: item.firstFeeding.includes('-') ? item.firstFeeding.split('-') : item.firstFeeding,
                secondValue: item.secondFeeding.includes('-') ? item.secondFeeding.split('-') : item.secondFeeding
            };
        });
        seasonsK.forEach(item => {
            objK[item.combination] = {
                autumnValue: item.mainFromAutumn.includes('-') ? item.mainFromAutumn.split('-') : item.mainFromAutumn,
                springValue: item.sowingInSpring.includes('-') ? item.sowingInSpring.split('-') : item.sowingInSpring,
                firstValue: item.firstFeeding.includes('-') ? item.firstFeeding.split('-') : item.firstFeeding,
                secondValue: item.secondFeeding.includes('-') ? item.secondFeeding.split('-') : item.secondFeeding
            };
        });
        return {
            1: objN,
            2: objP,
            3: objK
        };
    }

    cultures2Client = (data) => data.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) return 1;
        return 0;
    });
}

export default new Converter();

const n = {
    combination: '12',
    cultureGroupId: 5,
    fertilizerType: 'Азот',
    firstFeeding: '',
    id: 181,
    mainFromAutumn: '40-70',
    secondFeeding: '',
    sowingInSpring: '30-60'
};
const seasonDataWinter = {
    1: {
        abcd: {
            autumnValue: 50,
            springValue: 30,
            firstValue: [5, 20],
            secondValue: [5, 20]
        },
        abc: {
            autumnValue: [40, 50],
            springValue: [30, 40],
            firstValue: [10, 20]
        },
        abd: {
            autumnValue: [40, 50],
            springValue: [30, 40],
            secondValue: [10, 20]
        },
        ab: {
            autumnValue: [50, 70],
            springValue: [30, 50]
        },
        a: {
            autumnValue: [80, 100]
        }
    },
    2: {
        ab: {
            autumnValue: [80, 100],
            springValue: [10, 20]
        },
        a: {
            autumnValue: [90, 100]
        }
    },
    3: {
        a: {
            autumnValue: [40, 80]
        }
    }
};