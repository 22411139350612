import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation('mico-constants');
    return (
        {
            LOW: ['LOW', 'VERY_LOW'],
            cultureCompare: {
                cultures_match: t('cultures match'),
                groups_differ: t('groups differ'),
                groups_match: t('groups match')
            },
            mycobiotaCompare: {
                'phytopathogenic__very_high-suppressive__very_low': t('phytopathogenic very_high-suppressive very_low'),
                'phytopathogenic__high-suppressive__low': t('phytopathogenic high-suppressive low'),
                'phytopathogenic__normal-suppressive__normal': t('phytopathogenic normal-suppressive normal'),
                'phytopathogenic__normal-suppressive__low': t('phytopathogenic normal-suppressive low'),
                'phytopathogenic__low-suppressive__high': t('phytopathogenic low-suppressive high'),
                'phytopathogenic__very_low-suppressive__very_high': t('phytopathogenic very_low-suppressive very_high'),
                'phytopathogenic__very_low-suppressive__very_low': t('phytopathogenic very_low-suppressive very_low'),
                'phytopathogenic__low-suppressive__very_low': t('phytopathogenic low-suppressive very_low'),
                'phytopathogenic__very_low-suppressive__low': t('phytopathogenic very_low-suppressive low'),
                'phytopathogenic__low-suppressive__normal': t('phytopathogenic low-suppressive low'),
                'phytopathogenic__low-suppressive__low': t('phytopathogenic low-suppressive low')
            },
            interpretation: {
                very_high: t('very_high'),
                high: t('high'),
                normal: t('normal'),
                low: t('low'),
                very_low: t('very_low')
            }
        }
    );
};

export const PHASES = {
    Посев: 'Sowing',
    Всходы: 'Sprouts',
    Кущение: 'Tiller',
    'Выход в трубку': 'Exit to the phone',
    Колошение: 'Earing',
    Цветение: 'Bloom',
    'Молочная спелость': 'Milky ripeness',
    'Восковая спелость': 'Wax ripeness',
    'Полная спелость': 'Full Ripeness',
    'Выметывание метелки': 'Brooming',
    '5-6 листьев': '5-6 leaves',
    '9 лист/трубкование': '9 leaf/piping',
    'Трубкование до 9 узла': 'Tubing up to 9 knots',
    'Молочно-восковая спелость': 'Milky Wax Ripeness',
    'Возобновление кущения': 'Resumption of tillering',
    'Кущение после зимы': 'Tillering after winter',
    'Первая пара настоящих листьев': 'First Pair of True Leaves',
    'Розетка (первичная)': 'Outlet (Primary)',
    'Возобновление вегетации (вторичная розетка)': 'Vegetation Resuming (Secondary Rosette)',
    'Начало стеблевания (ветвления)': 'The Beginning of the Stem (Branch)',
    'Образование соцветий': 'Formation of inflorescences',
    'Начало бутонизации': 'The Beginning of Budding',
    'Образование первых стручков': 'Formation of the first pods'
};

export const PROBABILITY = {
    'Развитие невозможно': 'impossible',
    'Низкая вероятность возникновения': 'low_chance',
    'Риск начала заражения': 'risk_infection',
    'Риск развития эпифитотии': 'risk_epiphytosis',
    'Недостаточно данных': 'no_data',
    'Экспертные данные не найдены': 'data_not_found',
    'no data': 'no_data'
};