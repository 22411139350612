/**
 * Преобразует id и version в ключ
 * @param id
 * @param version
 * @returns {string}
 */
export const getKey = (id, version) => (`${id}_${version}`);

/**
 * Возвращает id и version из ключа
 * @param key
 * @returns {void|*|string[]|[]} [id, version]
 */
export const getIdVersion = key => (key.split('_'));

/**
 * Парсинг объекта по пути
 * @param object
 * @param path
 * @returns {*}
 */
export const getValueByObjectPath = (object, path = []) => {
    if (!path.length) return object;
    path.forEach(
        (element, index) => {
            object = object[element] || (path.length - 1 === index ? undefined : {});
        }
    );
    return object;
};

const now = () => {
    const time = Date.now();
    const last = now.last || time;
    return now.last = time > last ? time : last + 1;
};

/**
 * Генерит уникальный идентификатор
 * @returns {*}
 */
export const uid = () => now().toString(36);

/**
 * Проверяет Объект {} на пустоту
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * Возвращает базовое имя файла без пути
 * @param {string} filepath - имя файла с путём
 * @returns string
 */
export const getBaseName = filepath => filepath.match(/[^/\\]*$/)[0];

export const rus2Latin = str => {
    const ru = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'j',
        з: 'z',
        и: 'i',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'shch',
        ы: 'y',
        э: 'e',
        ю: 'u',
        я: 'ya'
    };
    const n_str = [];

    str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

    for (let i = 0; i < str.length; ++i) {
        n_str.push(
            (ru[str[i]])
            || (ru[str[i].toLowerCase()] === undefined && str[i])
            || (ru[str[i].toLowerCase()].replace(/^(.)/, (match) => match.toUpperCase()))
        );
    }
    return n_str.join('');
};

export const isValid = () => {
    const token = localStorage.getItem('token');
    const now = new Date();
    if (token && token.length) {
        const expiration = window.atob(token.split('.')[1]) && JSON.parse(window.atob(token.split('.')[1]));
        if (expiration && expiration.exp && (expiration.exp * 1000 - now.getTime() < 0)) {
            localStorage.removeItem('token');
            return false;
        }
        return true;
    }
    return false;
};

export const isValidToken = (token) => {
    const now = new Date();
    if (token && token.length) {
        const expiration = window.atob(token.split('.')[1]) && JSON.parse(window.atob(token.split('.')[1]));
        if (expiration && expiration.exp && (expiration.exp * 1000 - now.getTime() < 0)) {
            return false;
        }
        return true;
    }
    return false;
};


export const toFixed = (value, num = 1) => {
    const afterComma = 10 ** num;
    if (!num) {
        return value ? Math.round(value) : value;
    }
    return value ? Math.round(value * afterComma) / afterComma : value;
};