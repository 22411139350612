export default class Converter {
    /**
     * Метод трансформации клиентского объекта в серверный
     * @param client
     * @return {any}
     * @private
     */
    _client2Server(object) { // eslint-disable-line
        console.warn('Статический метод client2Server не переопределен. Происходит прозрачнное проксирование объекта');
        return this.clone(object);
    }

    /**
     * Метод трансформации серверного объекта в клиентский
     * @param server
     * @return {any}
     */
    _server2Client(object) { // eslint-disable-line
        console.warn('Статический метод server2Client не переопределен. Происходит прозрачнное проксирование объекта');
        return this.clone(object);
    }

    /**
     * Метод трансформации части клиенского объекта в серверный
     * По умолчанию используется метод проксирует функцию _client2Server
     * Вы можете переопределить данный метод в случае,
     *  если часть клиенского объекта не подходит для трансформации метода _client2Server
     * @param client
     * @private
     */
    _clientChunk2Server(object) {
        return this._client2Server(object);
    }

    /**
     * Метод клонирования объекта
     * @param object
     * @return {any}
     */
    clone(object) { // eslint-disable-line
        if (typeof object !== 'object') throw new Error('Функция clone работает только с объекатми');
        return { ...object };
    }

    /**
     * Метод удаления из объекта всех полей со значением undefined
     * @param rawObject
     */
    removeEmptyField(rawObject) { // eslint-disable-line
        const object = {};
        Object.keys(rawObject).forEach((key) => {
            const rawElement = rawObject[key];
            const elemetyType = typeof rawElement;
            switch (elemetyType) {
                case 'undefined': {
                    break;
                }
                case 'object': {
                    if (Array.isArray(rawElement) || rawElement == null) {
                        object[key] = rawElement;
                    } else {
                        object[key] = this.removeEmptyField(rawElement);
                    }
                    break;
                }
                default: {
                    object[key] = rawElement;
                }
            }
        });
        return object;
    }

    /**
     * Метод получения серверного тела объекта
     * @return {any}
     * @param object
     */
    toServer(object) {
        return this._client2Server(object);
    }

    /**
     * Метод получения серверного тела объекта
     * @return {any}
     * @param object
     */
    toClient(object) {
        return this._server2Client(object);
    }

    /**
     * Метод получения части серверного тела объекта
     * @param object
     */
    chunkToServer(object) {
        this._clientChunk2Server(object);
    }
}
