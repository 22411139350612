import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Tabs, Layout, Row, Col, Card, Form, Input, Table, Radio, Select, Collapse, Button, Checkbox
} from 'antd';
import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../models/account/selectors';
import { useClassName } from '../../utils/cn';
import { getRegionsCatalog } from '../../models/regions/selectors';
import { getRegions } from '../../models/regions/actions';
import { getMycoList } from '../../models/analysis/selectors';
import { getFieldsList } from '../../models/fields/selectors';
import { getFields, setField } from '../../models/fields/actions';
import { getCalculatorsMyco } from '../../models/analysis/actions';
import { setSelectedField } from '../../reducers/view/actions';
import ProfileInfo from '../../components/profile-info';
import ProfilePassword from '../../components/profile-password';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const Profile = () => {
    const user = useSelector(state => getUser(state))
    const regions = useSelector(state => getRegionsCatalog(state))
    const analysis = useSelector(state => getMycoList(state))
    const forecasts = useSelector(state => getFieldsList(state))
    const dispatch = useDispatch()
    const { t } = useTranslation('profile');
    const cn = useClassName('page');
    const history = useHistory();
    const [dataSource, setDataSource] = useState([])
    const columnsAnalysis = [
        {
            title: t('name'),
            dataIndex: 'name'
        },
        {
            title: t('date'),
            dataIndex: 'creation_date',
            render: value => new Date(value).toLocaleString('ru-Ru'),
            width: 200

        },
        {
            title: t('status'),
            dataIndex: 'is_done',
            render: (value) => (value ? t('calculate') : t('not calculate')),
            width: 150
        },
        {
            title: '',
            dataIndex: 'edit',
            render: (value, record) => (
                <div onClick={() => onEditRecord('analysis', record.id)}>
                    <EditOutlined />
                </div>
            ),
            width: 160
        }
    ]
    const columnsForecast = [
        {
            title: t('name'),
            dataIndex: 'vega_key'
        },
        {
            title: t('date'),
            dataIndex: 'forecast',
            render: (value) => new Date(value.created_at).toLocaleString('ru-Ru'),
            width: 200

        },
        {
            title: t('status'),
            dataIndex: 'is_done',
            render: () => t('calculate'),
            width: 150
        },
        {
            title: '',
            dataIndex: 'edit',
            render: (value, record) => (
                <div onClick={() => onEditRecord('forecast', record.vega_key)}>
                    <EditOutlined />
                </div>
            ),
            width: 160
        }
    ]
    const [columns, setColumns] = useState([...columnsAnalysis])


    useEffect(() => {
        dispatch(getRegions('?page_size=1000'))
        dispatch(getFields(`?page_size=1000&filter={"user":${user.id}}&sort=["forecast.created_at","ASC"]`))
        dispatch(getCalculatorsMyco('?page_size=1000&sort=["creation_date","ASC"]'))
    }, [])

    useEffect(() => {
        setDataSource([...analysis])
        setColumns([...columnsAnalysis])
    }, [analysis])

    const onValuesChange = (value) => {
        switch (value) {
            case 'forecasts': {
                setColumns([...columnsForecast])
                setDataSource([...forecasts])
                break
            }
            case 'analysis':
            default: {
                setColumns([...columnsAnalysis])
                setDataSource([...analysis])
                break
            }
        }
    }

    const onEditRecord = (type, id) => {
        if (type === 'forecast') {
            const selectedField = forecasts.find(item => item.vega_key === id)
            dispatch(setSelectedField(id))
            dispatch(setField(selectedField))
        }
        history.push(`/${type}/${id}`)
    }
    const onDeleteRecord = (id) => {

    }

    return (
        <Content className={cn()}>
            <Row gutter={30}>
                <Col span={17}>
                    <Tabs className={cn('main-tabs')}>
                        <TabPane tab={t('profile')} key="profile" className={cn('tabpane')}>
                            <Collapse className={cn('main-collapse')}>
                                <Panel header={t('my calculates')} key="1">
                                    <Select style={{ width: '300px', marginBottom: '30px' }} onChange={onValuesChange}>
                                        <Select.Option value="forecasts">
                                            {t('forecast')}
                                        </Select.Option>
                                        <Select.Option value="analysis">
                                            {t('myco')}
                                        </Select.Option>
                                    </Select>
                                    {dataSource ? (
                                        <Table dataSource={dataSource}
                                            columns={columns}
                                            pagination={false} />
                                    ) : (
                                        <>
                                            {t('no data')}
                                        </>
                                    )}
                                </Panel>
                            </Collapse>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span={7}>

                    <Card title={t('user info')}
                        bordered={false}>
                        <Tabs className={cn('sider-tabs')}>
                            <TabPane tab={t('change info')} key="change info">
                                <ProfileInfo t={t} cn={cn} />
                            </TabPane>
                            <TabPane tab={t('change pass')} key="change pass">
                                <ProfilePassword t={t} cn={cn} />
                            </TabPane>
                        </Tabs>
                    </Card>

                </Col>
            </Row>

        </Content>
    )
}

export default Profile