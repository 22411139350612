import * as constants from '../constants';
import converter from '../converter';

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getFieldShape.success: {
            return action.payload.results;
        }
        case constants.getFieldsShape.success: {
            return action.payload.results;
        }

        default:
            return state;
    }
}
