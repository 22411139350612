import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form, Collapse, Row, Col, Button, Select, InputNumber, Radio
} from 'antd'

import { InfoCircleFilled } from '@ant-design/icons';
import { useClassName } from '../../utils/cn';

import { selectors, actions } from '../../models';

import getConstants from '../constants'

const {
    setParamsCalculatorMyco
} = actions;

const {
    getCurMyco,
    getCulturesCatalog,
    getPrecedingMyco,
    getGenusOfMicroCatalogCalculatorsMyco,
    getTypeOfResearchCalculatorsMyco
} = selectors;

const { Panel } = Collapse

const Analysis = () => {
    const calculator = useSelector(state => getCurMyco(state));
    const micro = useSelector(state => getGenusOfMicroCatalogCalculatorsMyco(state));
    const cultures = useSelector(state => getCulturesCatalog(state));

    const constants = getConstants();

    const dispatch = useDispatch();

    const cn = useClassName('myco');
    const { t } = useTranslation('myco');
    const [sum, setSum] = useState(0);
    const [inputType, setInputType] = useState('koe');
    const [phytopathogenics, setPytopathogenic] = useState([]);
    const [curPhytopathogenic, setCurPytopathogenic] = useState([]);
    const [suppressives, setSuppressive] = useState([]);
    const [curSuppressive, setCurSuppressive] = useState([]);
    const [neutrals, setNeutral] = useState([]);
    const [curNeutral, setCurNeutral] = useState([]);

    const [form] = Form.useForm();
    useEffect(() => {
        setPytopathogenic([...micro.filter(item => (item.mycobiota_type && item.mycobiota_type.code === 'phytopathogenic'))]);
        setSuppressive([...micro.filter(item => (item.mycobiota_type && item.mycobiota_type.code === 'suppressive'))]);
        setNeutral([...micro.filter(item => (item.mycobiota_type && item.mycobiota_type.code === 'neutral'))]);
    }, [micro]);

    useEffect(() => {
        const {
            sampling_coordinates,
            interpretation,
            probe_number,
            culture,
            preceding_culture
        } = calculator;
        const {
            phytopathogenic,
            suppressive,
            neutral
        } = interpretation;
        const phytopathogenicArr = [];
        const phyt = [];
        const suppressiveArr = [];
        const sup = [];
        const neutralArr = [];
        const neu = [];
        const dataObj = {};
        phytopathogenic.micromycete.forEach(item => {
            const data = phytopathogenics.find(i => i.id === item.ref);
            phytopathogenicArr.push({
                id: item.ref,
                name: data && data.name,
                koe: item.koe,
                percentage: item.percentage,
                losses: item.losses
            });
            phyt.push(item.ref);
            dataObj[`${item.ref}-koe`] = item.koe && Number(item.koe.toFixed(2));
            dataObj[`${item.ref}-percentage`] = item.percentage && Number(item.percentage.toFixed(0));
        });
        suppressive.micromycete.forEach(item => {
            const data = suppressives.find(i => i.id === item.ref);
            suppressiveArr.push({
                id: item.ref,
                name: data && data.name,
                koe: item.koe,
                percentage: item.percentage,
                losses: item.losses
            });
            sup.push(item.ref);
            dataObj[`${item.ref}-koe`] = item.koe && Number(item.koe.toFixed(2));
            dataObj[`${item.ref}-percentage`] = item.percentage && Number(item.percentage.toFixed(0));
        });
        neutral.micromycete.forEach(item => {
            const data = neutrals.find(i => i.id === item.ref);
            neutralArr.push({
                id: item.ref,
                name: data && data.name,
                koe: item.koe,
                percentage: item.percentage,
                losses: item.losses
            });
            neu.push(item.ref);
            dataObj[`${item.ref}-koe`] = item.koe && Number(item.koe.toFixed(2));
            dataObj[`${item.ref}-percentage`] = item.percentage && Number(item.percentage.toFixed(0));
        });
        setCurPytopathogenic([...phytopathogenicArr]);
        setCurSuppressive([...suppressiveArr]);
        setCurNeutral([...neutralArr]);
        form.setFieldsValue({
            lat: sampling_coordinates.coordinates[0],
            lon: sampling_coordinates.coordinates[1],
            probe_number,
            culture: culture.id,
            preceding_culture: preceding_culture.id,
            phytopathogenicArr: phyt,
            suppressiveArr: sup,
            neutralArr: neu
        });
        form.setFieldsValue(dataObj);
    }, [calculator, phytopathogenics, suppressives, neutrals]);

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    const onFinish = (values) => {
        const sendValues = {};

        sendValues.culture = values.culture;
        sendValues.preceding_culture = values.preceding_culture;
        const report = [];
        values.phytopathogenicArr.forEach(item => {
            report.push({
                genus: item,
                koe: inputType === 'koe' ? values[`${item}-koe`] : null,
                percentage: inputType === 'percentage' ? values[`${item}-percentage`] : null
            });
        });
        values.suppressiveArr.forEach(item => {
            report.push({
                genus: item,
                koe: inputType === 'koe' ? values[`${item}-koe`] : null,
                percentage: inputType === 'percentage' ? values[`${item}-percentage`] : null
            });
        });
        values.neutralArr.forEach(item => {
            report.push({
                genus: item,
                koe: inputType === 'koe' ? values[`${item}-koe`] : null,
                percentage: inputType === 'percentage' ? values[`${item}-percentage`] : null
            });
        });
        sendValues.report = [...report];
        dispatch(setParamsCalculatorMyco({ id: calculator.id, values: sendValues }));
    };

    const onFieldsChange = (field, fields) => {};
    const onValuesChange = (value, values) => {
        const phytopathogenicArr = [];
        const suppressiveArr = [];
        const neutralArr = [];
        let sum = 0;
        Object.keys(values).forEach(item => {
            if (
                item.includes('percentage') && (
                    phytopathogenicArr.includes(Number(item.split('-')[0]))
                    || suppressiveArr.includes(Number(item.split('-')[0]))
                    || neutralArr.includes(Number(item.split('-')[0])))
            ) {
                sum += Number(values[item]);
            }
        });
        setSum(sum);
        if (Object.keys(value)[0] === 'phytopathogenicArr') {
            values.phytopathogenicArr.forEach(item => {
                const data = phytopathogenics.find(i => i.id === item);
                const curPhyto = curPhytopathogenic.find(i => i.id === item);
                if (curPhyto) {
                    phytopathogenicArr.push(curPhyto);
                } else {
                    phytopathogenicArr.push({
                        id: item, name: data && data.name, koe: null, percentage: null
                    });
                }
            });
            setCurPytopathogenic([...phytopathogenicArr]);
        }
        if (Object.keys(value)[0] === 'suppressiveArr') {
            values.suppressiveArr.forEach(item => {
                const data = suppressives.find(i => i.id === item);
                const curSup = curSuppressive.find(i => i.id === item);
                if (curSup) {
                    suppressiveArr.push(curSup);
                } else {
                    suppressiveArr.push({
                        id: item, name: data && data.name, koe: null, percentage: null
                    });
                }
            });
            setCurSuppressive([...suppressiveArr]);
        }
        if (Object.keys(value)[0] === 'neutralArr') {
            values.neutralArr.forEach(item => {
                const data = neutrals.find(i => i.id === item);
                const curNeut = curSuppressive.find(i => i.id === item);
                if (curNeut) {
                    neutralArr.push(curNeut);
                } else {
                    neutralArr.push({
                        id: item, name: data && data.name, koe: null, percentage: null
                    });
                }
            });
            setCurNeutral([...neutralArr]);
        }
    };

    const onChange = (e) => {
        const curType = inputType;
        setInputType(e.target.value);
        const values = form.getFieldsValue(true);
        Object.keys(values).forEach(val => {
            if (val.includes(curType)) form.setFieldsValue({ [val]: '' });
        });
    };

    const notNull = (_, value) => {
        if (value > 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('not null')));
    };

    return (
        <Form onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            onValuesChange={onValuesChange}
            form={form}
            className={cn()}
            name="calculator-params">
            <div style={{ marginTop: '16px' }}>
                {calculator.id && (
                    <Row gutter={20}>
                        <Col span={8}>
                            <div className="form-field">
                                <div className="form-label">{t('culture choice')}</div>
                                <Form.Item
                                    name="culture"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Select
                                        showSearch
                                        filterOption={filterOption}>
                                        {
                                            cultures.map(
                                                (item =>
                                                    (
                                                        <Select.Option value={item.id} key={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="form-field">
                                <div className="form-label">{t('predecessor choice')}</div>
                                <Form.Item
                                    name="preceding_culture"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Select
                                        showSearch
                                        filterOption={filterOption}>
                                        {
                                            cultures.map(
                                                (item =>
                                                    (
                                                        <Select.Option value={item.id} key={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        {calculator.cultures_comment && (
                            <Col span={24} style={{ marginBottom: '20px' }}>
                                <div className="info-description">
                                    <InfoCircleFilled />
                                    {constants.cultureCompare[calculator.cultures_comment]}
                                </div>
                            </Col>
                        )}
                    </Row>
                )}
                <div className={cn('row-table')}>
                    {calculator.id && (
                        <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <Col span={24}>
                                {t('contains type')}
                                <Radio.Group onChange={onChange} defaultValue="koe" style={{ paddingLeft: '30px' }}>
                                    <Radio value="koe">{t('koe')}</Radio>
                                    <Radio value="percentage">{t('percent')}</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    )}
                    <Collapse ghost
                        className={cn('collapse-panel')}
                        collapsible={calculator.id ? 'header' : 'disabled'}>
                        <Panel header={t('phytopathogenic')} key="1">
                            <Row gutter={20}
                                justify="space-around"
                                align="middle"
                                className={cn('head-row')}>
                                <Col span={5}>
                                    {t('genus of micro')}
                                </Col>
                                <Col span={5}>
                                    {t('contains koe')}
                                </Col>
                                <Col span={5}>
                                    {t('contains percent')}
                                </Col>
                                <Col span={4}>
                                    {t('yield')}
                                </Col>
                                <Col span={5}>
                                    {t('contains group')}
                                </Col>
                            </Row>
                            <Row gutter={20}
                                justify="space-around"
                                align="middle">
                                <Col span={5}>
                                    <div className="form-label">{t('choose from list')}</div>
                                    <Form.Item name="phytopathogenicArr">
                                        <Select mode="multiple"
                                            showSearch
                                            allowClear>
                                            {phytopathogenics.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={14} />
                                <Col span={5} className={cn('input')}>
                                    {calculator?.interpretation?.phytopathogenic?.percentage?.toFixed(0)}
                                </Col>
                            </Row>
                            {curPhytopathogenic.map((item, i) => (
                                <Row gutter={20} key={item.id}>
                                    <Col span={5}>
                                        {item.name}
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item name={`${item.id}-koe`} rules={inputType === 'koe' && [{ validator: notNull }]}>
                                            <InputNumber
                                                min={0}
                                                decimalSeparator=","
                                                disabled={inputType === 'percentage'}
                                                placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item name={`${item.id}-percentage`} rules={inputType === 'percentage' && [{ validator: notNull }]}>
                                            <InputNumber
                                                min={0}
                                                decimalSeparator=","
                                                disabled={inputType === 'koe'}
                                                placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        {item.losses && item.losses.toFixed(2)}
                                    </Col>
                                </Row>
                            ))}

                            {calculator.interpretation.phytopathogenic.interpretation && (
                                <Row style={{ marginTop: '20px' }}>
                                    <b className={cn('primary-text')}>
                                        {t('interpretation', { value: constants.interpretation[calculator.interpretation.phytopathogenic.interpretation] })}
                                    </b>
                                </Row>
                            )}
                        </Panel>
                        <Panel header={t('suppressive')} key="2">
                            <Row gutter={20}
                                justify="space-around"
                                align="middle"
                                className={cn('head-row')}>
                                <Col span={5}>
                                    {t('genus of micro')}
                                </Col>
                                <Col span={5}>
                                    {t('contains koe')}
                                </Col>
                                <Col span={5}>
                                    {t('contains percent')}
                                </Col>
                                <Col span={4}>
                                    {t('yield')}
                                </Col>
                                <Col span={5}>
                                    {t('contains group')}
                                </Col>
                            </Row>
                            <Row gutter={20}
                                justify="space-around"
                                align="middle">
                                <Col span={5}>
                                    <div className="form-label">{t('choose from list')}</div>
                                    <Form.Item name="suppressiveArr">
                                        <Select mode="multiple"
                                            showSearch
                                            allowClear>
                                            {suppressives.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={14} />
                                <Col span={5} className={cn('input')}>
                                    {calculator.interpretation.suppressive.percentage.toFixed(0)}
                                </Col>
                            </Row>
                            {
                                curSuppressive.map((item, i) => (
                                    <Row gutter={20} key={item.id}>
                                        <Col span={5}>
                                            {item.name}
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item name={`${item.id}-koe`} rules={inputType === 'koe' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={inputType === 'percentage'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item name={`${item.id}-percentage`} rules={inputType === 'percentage' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={inputType === 'koe'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            {item.losses && item.losses.toFixed(2)}
                                        </Col>
                                    </Row>
                                ))
                            }
                            {calculator.interpretation.suppressive.interpretation && (
                                <Row style={{ marginTop: '20px' }}>
                                    <b className={cn('primary-text')}>
                                        {t('interpretation', { value: constants.interpretation[calculator.interpretation.suppressive.interpretation] })}
                                    </b>
                                </Row>
                            )}
                        </Panel>
                        <Panel header={t('neutral')} key="3">
                            <Row gutter={20}
                                justify="space-around"
                                align="middle"
                                className={cn('head-row')}>
                                <Col span={5}>
                                    {t('genus of micro')}
                                </Col>
                                <Col span={5}>
                                    {t('contains koe')}
                                </Col>
                                <Col span={5}>
                                    {t('contains percent')}
                                </Col>
                                <Col span={4}>
                                    {t('yield')}
                                </Col>
                                <Col span={5}>
                                    {t('contains group')}
                                </Col>
                            </Row>
                            <Row gutter={20}
                                justify="space-around"
                                align="middle">
                                <Col span={5}>
                                    <div className="form-label">{t('choose from list')}</div>
                                    <Form.Item name="neutralArr">
                                        <Select mode="multiple">
                                            {neutrals.map(item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={14} />
                                <Col span={5}>
                                    {calculator.interpretation.neutral.percentage.toFixed(0)}
                                </Col>
                            </Row>
                            {
                                curNeutral.map((item, i) => (
                                    <Row gutter={20} key={item.id}>
                                        <Col span={5}>
                                            {item.name}
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item name={`${item.id}-koe`} rules={inputType === 'koe' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={inputType === 'percentage'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item name={`${item.id}-percentage`} rules={inputType === 'percentage' && [{ validator: notNull }]}>
                                                <InputNumber
                                                    min={0}
                                                    decimalSeparator=","
                                                    disabled={inputType === 'koe'}
                                                    placeholder="" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            {item.losses && item.losses.toFixed(2)}
                                        </Col>
                                    </Row>
                                ))
                            }
                            {calculator.interpretation.neutral.interpretation && (
                                <Row style={{ marginTop: '20px' }}>
                                    <b className={cn('primary-text')}>
                                        {t('interpretation', { value: constants.interpretation[calculator.interpretation.neutral.interpretation] })}
                                    </b>
                                </Row>
                            )}
                        </Panel>
                    </Collapse>
                </div>


                { calculator.interpretation.conclusion && (
                    <Row style={{ marginTop: '40px', marginBottom: '20px' }}>
                        <Col>
                            <div className="info-description">
                                <InfoCircleFilled />
                                {constants.mycobiotaCompare[calculator.interpretation.conclusion]}
                            </div>
                        </Col>
                    </Row>
                )}

            </div>
            {calculator.id && (
                <Row className={cn('button')} style={{ marginTop: '20px' }}>
                    <Col>
                        <Button
                            type="primary"
                            disabled={sum > 100}
                            htmlType="submit"
                            size="large">
                            {t('calculate')}
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    );
}

export default Analysis