import { getConstant } from '../utils';

export const getField = getConstant('GET_FIELD');
export const setField = 'SET_FIELD';
export const getFields = getConstant('GET_FIELDS');
export const getFieldShape = getConstant('GET_FIELD_SHAPE');
export const getFieldsShape = getConstant('GET_FIELDS_SHAPE');
export const getFieldForecast = getConstant('GET_FIELD_FORECAST');
export const updateFieldForecast = getConstant('UPDATE_FIELD_FORECAST');
export const clearField = getConstant('CLEAR_FIELD');

export const getPestProbabilityData = getConstant('GET_PEST_PROBABILITY_DATA');
export const calculatePestProbability = getConstant('CALCULATE_PEST_PROBABILITY');