import options from './options';
import axiosInstance from '../../utils/axios';

const urlPath = (url, path) => `${url}${path}`;

export const getFields = filter => axiosInstance.get(
    urlPath(options.apiFieldsUrl, filter)
);

export const getField = id => axiosInstance.get(
    urlPath(options.apiFieldsUrl, `${id}/`)
);

export const getFieldShape = filter => axiosInstance.get(
    urlPath(options.apiFieldShapeUrl, filter)
);

export const getFieldsShape = filter => axiosInstance.get(
    urlPath(options.apiFieldsShapeUrl, filter)
);

export const getFieldForecast = id => axiosInstance.get(
    urlPath(options.apiFieldsUrl, `${id}/forecast/`)
);

export const updateFieldForecast = ({ id, body }) => axiosInstance.patch(
    urlPath(options.apiFieldsUrl, `${id}/forecast/`),
    body
);

export const calculatePestProbability = ({ id, body }) => axiosInstance.post(
    urlPath(options.apiFieldsUrl, `${id}/pest_probability/`),
    body
);

export const getPestProbabilityData = ({ id, body }) => axiosInstance.post(
    urlPath(options.apiFieldsUrl, `${id}/field_forecast/`),
    body
);
