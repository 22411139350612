import { combineReducers } from 'redux';

import field from './field';
import fields from './fields';
import shape from './shape';
import probabilityData from './probability-data';
import loading from './loading';

export default combineReducers({
    field,
    fields,
    shape,
    probabilityData,
    loading
});
