import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CalculatorData from './analysis-create';
import CalculatorParamsMyco from './analysis';
import { getCalculatorMyco } from '../../models/analysis/actions';

import './style.less'

export default () => {
    const { id } = useParams()
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getCalculatorMyco(id))
        }
    }, [id])
    return (
        <>
            <CalculatorData />
            <CalculatorParamsMyco />
        </>

    );
}