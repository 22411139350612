import * as constants from './constants';

export const getCultures = payload => ({
    type: constants.getCultures.toString(),
    payload
});

export const getCulture = payload => ({
    type: constants.getCulture.toString(),
    payload
});

export const clearCultures = () => ({
    type: constants.clearCultures.toString()
});