import { combineReducers } from 'redux';

import token from './token';
import isAuth from './is-auth';
import user from './user';

export default combineReducers({
    token,
    isAuth,
    user
});
