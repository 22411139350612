import React, { useState, useEffect } from 'react';
import {
    MapContainer,
    TileLayer,
    GeoJSON, useMap
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from '../../models';

import 'leaflet/dist/leaflet.css';
import { getCurShapes } from '../../models/fields/selectors';
import { checkedFields } from '../../reducers/view/selectors';

const {
    getCurShape,
    isShapeLoading,
    getUserId
} = selectors;

export default () => {
    const { t } = useTranslation('map');
    const userId = useSelector(state => getUserId(state));
    const selectedFields = useSelector(state => checkedFields(state));
    const shapes = useSelector(state => getCurShapes(state))
    const [geoJson, setGeoJson] = useState({});
    const [drawMap, setDraw] = useState({});
    const [center, setCenter] = useState([54, 44]);
    const [geoJSON, setGeoJSON] = useState([]);

    useEffect(() => {
        const selectedShapes = shapes.filter(item => selectedFields.includes(item.id))
        if (selectedShapes.length > 0) {
            const coordinates = getShapeCoordinates(selectedShapes);
            const centroid = getCentroid(coordinates);
            const center = [centroid[1], centroid[0]];
            setCenter([...center]);
            setGeoJSON(createGeoJSON(selectedShapes));
        } else {
            setGeoJSON([])
        }
    }, [shapes, selectedFields]);

    const createGeoJSON = (data) => {
        const arr = [];
        data.forEach(item => {
            const shape = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: { ...item.shape }
                    }
                ]
            };
            const style = () => ({ color: item.culture?.color || 'green' });
            arr.push({ shape, style, id: item.id });
        });
        return arr;
    };

    const getCentroid = (coordinates) => {
        const coord = { lat: 0, lan: 0 };
        coordinates.forEach((item) => {
            coord.lat += item[0];
            coord.lan += item[1];
        });
        return [coord.lat / coordinates.length, coord.lan / coordinates.length];
    };

    const getShapeCoordinates = (data) => {
        let coordinates = [];
        data.forEach(item => {
            coordinates = coordinates.concat(item.shape?.coordinates[0].flat(1));
        });
        return coordinates;
    };

    const ChangeView = ({ center }) => {
        const map = useMap();
        map.setView(center, 11);
        return null;
    };

    const Map = ({ geoJSON, center }) => (
        <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {
                geoJSON.map((item) => <GeoJSON data={item.shape} style={item.style} />)
            }
        </MapContainer>
    )

    return (
        <Map geoJSON={geoJSON} center={center} />
    );
};