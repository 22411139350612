import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Dropdown, Menu, Space, Layout
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useClassName } from '../../utils/cn';
import { setSelectedMenu } from '../../reducers/view/actions';
import { getUser } from '../../models/account/selectors';
import { logout } from '../../models/account/actions';

import './style.less'

const { Header } = Layout
const MenuProps = ({
    setMenu, logout, t, history
}) => (
    <Menu
        items={[
            {
                label: <div onClick={() => { history.push('/fields'); setMenu('current') }}>{t('current')}</div>,
                key: 'current'
            },
            {
                label: <div onClick={() => { history.push('/fields'); setMenu('forecast') }}>{t('forecast')}</div>,
                key: 'forecast'
            },
            {
                label: <div onClick={() => { history.push('/fields'); setMenu('instruments') }}>{t('instruments')}</div>,
                key: 'instruments'
            },
            {
                label: <div onClick={() => { history.push('/profile') }}>{t('calculate')}</div>,
                key: 'calculate'
            },
            {
                label: <div onClick={() => history.push('/profile')}>{t('profile')}</div>,
                key: 'profile'
            },
            {
                label: <div onClick={logout}>{t('logout')}</div>,
                key: 'logout'
            }
        ]} />
);

export default () => {
    const { t } = useTranslation('header');
    const cn = useClassName('header');
    const dispatch = useDispatch()
    const user = useSelector(state => getUser(state));

    const history = useHistory();

    const setMenu = (key) => {
        dispatch(setSelectedMenu(key))
    };

    const onLogout = () => {
        dispatch(logout())
    }

    return (
        <Header className={cn()}>
            <Dropdown className={cn('dropdown')}
                overlayClassName={cn('dropdown-list')}
                overlay={<MenuProps setMenu={setMenu} logout={onLogout} t={t} history={history} />}
                trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {user.username}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Header>
    )
}