import * as constants from './constants';

export const getCalculatorsMyco = payload => ({
    type: constants.getCalculatorsMyco.toString(),
    payload
});

export const getCalculatorMyco = payload => ({
    type: constants.getCalculatorMyco.toString(),
    payload
});

export const addCalculatorMyco = payload => ({
    type: constants.addCalculatorMyco.toString(),
    payload
});

export const closeCalculatorMyco = payload => ({
    type: constants.closeCalculatorMyco.toString(),
    payload
});

export const removeCalculatorMyco = payload => ({
    type: constants.removeCalculatorMyco.toString(),
    payload
});

export const clearCalculatorMyco = payload => ({
    type: constants.clearCalculatorMyco.toString()
});

export const setParamsCalculatorMyco = payload => ({
    type: constants.setParamsCalculatorMyco.toString(),
    payload
});


export const getCultureCalculatorsMyco = payload => ({
    type: constants.getCultureCalculatorsMyco.toString(),
    payload
});


export const getPrecedingCalculatorsMyco = payload => ({
    type: constants.getPrecedingCalculatorsMyco.toString(),
    payload
});


export const getGenusOfMicroCalculatorsMyco = payload => ({
    type: constants.getGenusOfMicroCalculatorsMyco.toString(),
    payload
});

export const getGenusOfMicroCalculatorsMycoItem = payload => ({
    type: constants.getGenusOfMicroCalculatorsMycoItem.toString(),
    payload
});


export const getTypeOfResearchCalculatorsMyco = payload => ({
    type: constants.getTypeOfResearchCalculatorsMyco.toString(),
    payload
});

export const getTypeOfResearchCalculatorsMycoItem = payload => ({
    type: constants.getTypeOfResearchCalculatorsMycoItem.toString(),
    payload
});
