import React from 'react';
import { useSelector } from 'react-redux';
import {
    Tabs, Layout, Row, Col, Card, Form, Input, Select, Collapse, Button
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useClassName } from '../../utils/cn';
import { getRegionsCatalog } from '../../models/regions/selectors';
import Analysis from '../../components/analysis';

const { Content } = Layout;
const { TabPane } = Tabs;

const Profile = () => {
    const regions = useSelector(state => getRegionsCatalog(state))
    const { t } = useTranslation('profile');
    const cn = useClassName('page');


    return (
        <Content className={cn()}>
            <Row gutter={30}>
                <Col span={17}>
                    <Tabs className={cn('main-tabs')}>
                        <TabPane tab={t('my calculates')} key="profile" className={cn('tabpane')}>
                            <Analysis />
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span={7}>

                    <Card title={t('user info')}
                        bordered={false}>
                        <Tabs className={cn('sider-tabs')}>
                            <TabPane tab={t('change info')} key="change info">
                                <Form>
                                    <div className="form-field">
                                        <div className="form-label">
                                            {t('phone')}
                                        </div>
                                        <Form.Item
                                            name="phone"
                                            rules={[{ required: true, message: t('not empty') }]}>
                                            <Input validator="phone" placeholder={t('phone placeholder')} />
                                        </Form.Item>
                                    </div>
                                    <div className="form-field">
                                        <div className="form-label">
                                            {t('email')}
                                        </div>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: t('not empty') }]}>
                                            <Input placeholder={t('email placeholder')} />
                                        </Form.Item>
                                    </div>
                                    <div className="form-field">
                                        <div className="form-label">
                                            {t('region')}
                                        </div>
                                        <Form.Item
                                            name="region"
                                            rules={[{ required: true, message: t('not empty') }]}>
                                            <Select>
                                                {regions?.map(item => (
                                                    <Select.Option value={item.value}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <Row>
                                        <Col span={24} className={cn('button-block')}>
                                            <Button type="primary" htmlType="submit" size="large">
                                                {t('start')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                            <TabPane tab={t('change pass')} key="change pass">
                                <Form>
                                    <div className="form-field">
                                        <div className="form-label">
                                            {t('old password')}
                                        </div>
                                        <Form.Item
                                            name="oldPassword"
                                            rules={[{ required: true, message: t('not empty') }]}>
                                            <Input placeholder="********" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-field">
                                        <div className="form-label">
                                            {t('new password')}
                                        </div>
                                        <Form.Item
                                            name="newPassword"
                                            rules={[{ required: true, message: t('not empty') }]}>
                                            <Input placeholder="********" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-field">
                                        <div className="form-label">
                                            {t('retype password')}
                                        </div>
                                        <Form.Item
                                            name="retypePassword"
                                            rules={[{ required: true, message: t('not empty') }]}>
                                            <Input placeholder="********" />
                                        </Form.Item>
                                    </div>
                                    <Row>
                                        <Col span={24} className={cn('button-block')}>
                                            <Button type="primary" htmlType="submit" size="large">
                                                {t('start')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                        </Tabs>
                    </Card>

                </Col>
            </Row>

        </Content>
    )
}

export default Profile