import { combineReducers } from 'redux';

import account from './account';
import fields from './fields';
import analysis from './analysis';
import cultures from './cultures';
import regions from './regions';

const modules = [
    account,
    fields,
    analysis,
    cultures,
    regions
];

export default modules;

export const actions = modules.reduce(
    (previousValue, { actions }) => ({ ...previousValue, ...actions }), {}
);

export const constants = modules.reduce(
    (previousValue, { constants }) => ({ ...previousValue, ...constants }), {}
);

export const selectors = modules.reduce(
    (previousValue, { selectors }) => ({ ...previousValue, ...selectors }), {}
);

export const reducers = combineReducers(
    modules.reduce(
        (previousValue, { reducers, options }) => (reducers
            ? ({ ...previousValue, [options.name]: reducers }) : previousValue), {}
    )
);

export const saga = modules.reduce(
    (previousValue, { saga }) => (saga ? [...previousValue, saga] : previousValue), []
);
