import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Form, Input, Row, Select
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { getUser } from '../../models/account/selectors';
import { getRegionsCatalog } from '../../models/regions/selectors';
import { updateProfile } from '../../models/account/actions';

const ProfileInfo = ({ t, cn }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => getUser(state))
    const regions = useSelector(state => getRegionsCatalog(state))
    const [form] = useForm()

    useEffect(() => {
        const {
            email,
            region,
            phone,
            about
        } = user;
        form.setFieldsValue({
            email,
            region: region && region.id,
            phone,
            about
        })
    }, [user])

    const onFinish = (values) => {
        dispatch(updateProfile(values));
    }

    return (
        <Form form={form} onFinish={onFinish}>
            <div className="form-field">
                <div className="form-label">
                    {t('phone')}
                </div>
                <Form.Item
                    name="phone">
                    <Input validator="phone" placeholder={t('phone placeholder')} />
                </Form.Item>
            </div>
            <div className="form-field">
                <div className="form-label">
                    {t('email')}
                </div>
                <Form.Item
                    name="email">
                    <Input placeholder={t('email placeholder')} />
                </Form.Item>
            </div>
            <div className="form-field">
                <div className="form-label">
                    {t('region')}
                </div>
                <Form.Item
                    name="region">
                    <Select>
                        {regions?.map(item => (
                            <Select.Option value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
            <Row>
                <Col span={24} className={cn('button-block')}>
                    <Button type="primary" htmlType="submit" size="large">
                        {t('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ProfileInfo