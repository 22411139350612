import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';
import * as actions from './actions';

function* loginSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.login.try,
        constants.login.success,
        constants.login.error
    );
    try {
        yield put(start());
        const data = yield call(api.login, payload);
        yield put(success(data));
        yield put(actions.getProfileInfo());
    } catch (error) {
        yield put(failure(error));
    }
}

function* registerSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.register.try,
        constants.register.success,
        constants.register.error
    );
    try {
        yield put(start());
        yield call(api.register, payload);
        yield put(success());
        yield put(actions.login(payload));
    } catch (error) {
        yield put(failure(error));
    }
}

function* getProfileSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.getProfile.try,
        constants.getProfile.success,
        constants.getProfile.error
    );
    try {
        yield put(start());
        yield call(api.getProfile);
        yield put(success());
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.login.toString(),
            loginSaga
        ),
        yield takeLatest(
            constants.verify.toString(),
            defaultSaga({
                constants: constants.verify,
                api: api.verify
            })
        ),
        yield takeLatest(
            constants.refresh.toString(),
            defaultSaga({
                constants: constants.refresh,
                api: api.refresh
            })
        ),
        yield takeLatest(
            constants.getProfile.toString(),
            defaultSaga({
                constants: constants.getProfile,
                api: api.getProfile
            })
        ),
        yield takeLatest(
            constants.getProfileInfo.toString(),
            defaultSaga({
                constants: constants.getProfileInfo,
                api: api.getProfileInfo
            })
        ),
        yield takeLatest(
            constants.updateProfile.toString(),
            defaultSaga({
                constants: constants.updateProfile,
                api: api.updateProfile
            })
        ),
        yield takeLatest(
            constants.userActivation.toString(),
            defaultSaga({
                constants: constants.userActivation,
                api: api.userActivation
            })
        ),
        yield takeLatest(
            constants.resetPasswordConfirm.toString(),
            defaultSaga({
                constants: constants.resetPasswordConfirm,
                api: api.resetPasswordConfirm
            })
        ),
        yield takeLatest(
            constants.resetPassword.toString(),
            defaultSaga({
                constants: constants.resetPassword,
                api: api.resetPassword
            })
        ),
        yield takeLatest(
            constants.register.toString(),
            registerSaga
        )
    ]);
}