import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
const refresh = localStorage.getItem('refresh') ? localStorage.getItem('refresh') : null;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async req => {
    if (token) {
        req.headers.Authorization = `JWT ${token}`;

        const user = jwt_decode(token);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

        if (!isExpired) return req;

        const response = await axios.post('/auth/jwt/refresh/', {
            refresh
        });
        console.log(response);
        localStorage.setItem('token', response.data?.access);
        req.headers.Authorization = `JWT ${response.data.access}`;
    }
    console.log(req);
    return req;
});


export default axiosInstance;