import ConverterClass from '../../utils/converter';
import { toFixed } from '../../utils/utils';

class Converter extends ConverterClass {
    _server2Client = (item = {}) => ({
        id: item.id || null,
        culture: item.culture || {},
        region: item.region || {},
        field_num: item.field_num || null,
        google_disk_url: item.google_disk_url,
        mechanical_composition: item.mechanical_composition || {},
        degree_of_soil_moisture: item.degree_of_soil_moisture || {},
        fertilizer_action_year: item.fertilizer_action_year || {},
        preceding_culture: item.preceding_culture || {},
        created: item.created || null,
        updated: item.updated || null,
        owner: item.owner || null,
        vega_key: item.vega_key || null,
        method: item.method || null,
        planned_yield: item.planned_yield || 0,
        soil_sampling_depth: item.soil_sampling_depth || null,
        depth_of_arable_layer: item.depth_of_arable_layer || null,
        ph_salt: item.ph_salt || null,
        p2o5: item.p2o5 || null,
        k2o: item.k2o || null,
        no3: item.no3 || null,
        s: item.s || null,
        organic_substances: item.organic_substances || null,
        nh4: item.nh4 || null,
        ca: item.ca || null,
        mg: item.mg || null,
        na: item.na || null,
        so4: item.so4 || null,
        cl: item.cl || null,
        salt_conductivity: item.salt_conductivity || null,
        ph_water: item.ph_water || null,
        hg: item.hg || null,
        v: item.v || null,
        b: item.b || null,
        mn: item.mn || null,
        cu: item.cu || null,
        mo: item.mo || null,
        co: item.co || null,
        zn: item.zn || null,
        pb: item.pb || null,
        ni: item.ni || null,
        district: item.district || null,
        oktmo: item.oktmo || null,
        farmland_type: item.farmland_type || null,
        soil_type: item.soil_type || null,
        calculator: this.calculateToClient(item.calculator || {}),
        user: item.user,
        forecast: item.forecast || {},
        forecast_phases: item.forecast_phases || item.forecast || {},
        forecast_error: false
    });

    calculateToClient = (data) => ({ // eslint-disable-line
        id: data.id,
        calculator_type: data.calculator_type,
        name: data.name,
        is_done: data.is_done || false,
        created_at: data.created_at,
        updated_at: data.updated_at,
        user: data.user,
        normative: this.normativeToClient(data.normative || {}),
        nutrition: this.nutritionToClient(data.nutrition || {}),
        rb: this.rbToClient(data.rb || {}),
        distribution: this.distributionToClient(data.distribution || {}),
        agrochemical: this.agrochemicalToClient(data.agrochemical || {})
    });

    agrochemicalToClient = (data = {}) => ({ // eslint-disable-line
        id: data.id,
        user: data.user,
        created_at: data.created_at,
        updated_at: data.updated_at,
        errors: data.errors || {},
        ph_water_interval: data.ph_water_interval,
        ph_salt_interval: data.ph_salt_interval,
        nh4_interval: data.nh4_interval,
        no3_interval: data.no3_interval,
        p2o5_interval: data.p2o5_interval,
        k2o_interval: data.k2o_interval,
        s_interval: data.s_interval,
        ca_interval: data.ca_interval,
        na_interval: data.na_interval,
        mg_interval: data.mg_interval,
        so4_interval: data.so4_interval,
        cl_interval: data.cl_interval,
        organic_substances_interval: data.organic_substances_interval,
        salt_conductivity_interval: data.salt_conductivity_interval,
        compare_ph_water_with_ph_salt_interval: data.compare_ph_water_with_ph_salt_interval,
        compare_no3_with_nh4_interval: data.compare_no3_with_nh4_interval,
        ph_water: data.ph_water,
        ph_salt: data.ph_salt,
        nh4: data.nh4,
        no3: data?.no3?.toFixed(2),
        p2o5: data.p2o5,
        k2o: data.k2o,
        s: data.s,
        ca: data.ca,
        na: data.na,
        mg: data.mg,
        so4: data.so4,
        cl: data.cl,
        organic_substances: data?.organic_substances?.toFixed(2),
        salt_conductivity: data.salt_conductivity,
        method: data.method
    })

    normativeToClient = (data = {}) => ({
        id: data.id,
        user: data.user,
        created_at: data.created_at,
        updated_at: data.updated_at,
        region: data.region || {},
        culture: data.culture || {},
        preceding_culture: data.preceding_culture || {},
        mechanical_composition: data.mechanical_composition || {},
        ph_salt: data.ph_salt,
        no3: data.no3,
        k2o: data.k2o,
        p2o5: data.p2o5,
        analysis_method: data.analysis_method,
        planned_yield: data.planned_yield,
        recommended_n_main: toFixed(data.recommended_n_main, 1),
        recommended_p_main: toFixed(data.recommended_p_main, 1),
        recommended_k_main: toFixed(data.recommended_k_main, 1),
        recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
        recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
        recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
        recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
        recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
        recommended_k_feeding: toFixed(data.recommended_k_feeding, 1),
        expert_comment: data.expert_comment,
        expert_k_feeding: toFixed(data.expert_k_feeding, 1),
        expert_k_main: toFixed(data.expert_k_main, 1),
        expert_k_sowing: toFixed(data.expert_k_sowing, 1),
        expert_n_feeding: toFixed(data.expert_n_feeding, 1),
        expert_n_main: toFixed(data.expert_n_main, 1),
        expert_n_sowing: toFixed(data.expert_n_sowing, 1),
        expert_p_feeding: toFixed(data.expert_p_feeding, 1),
        expert_p_main: toFixed(data.expert_p_main, 1),
        expert_p_sowing: toFixed(data.expert_p_sowing, 1),
        expert_data: data.expert_k_feeding
                || data.expert_k_main
                || data.expert_k_sowing
                || data.expert_n_feeding
                || data.expert_n_main
                || data.expert_n_sowing
                || data.expert_p_feeding
                || data.expert_p_main
                || data.expert_p_sowing || false
    })

    nutritionToClient = (data = {}) => ({
        id: data.id,
        user: data.user,
        created_at: data.created_at,
        updated_at: data.updated_at,
        region: data.region || {},
        culture: data.culture || {},
        preceding_culture: data.preceding_culture || {},
        mechanical_composition: data.mechanical_composition || {},
        ph_salt: data.ph_salt,
        no3: data.no3,
        k2o: data.k2o,
        p2o5: data.p2o5,
        method: data.method,
        planned_yield: data.planned_yield,
        recommended_n_main: toFixed(data.recommended_n_main, 1),
        recommended_p_main: toFixed(data.recommended_p_main, 1),
        recommended_k_main: toFixed(data.recommended_k_main, 1),
        recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
        recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
        recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
        recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
        recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
        recommended_k_feeding: toFixed(data.recommended_k_feeding, 1)
    })

    rbToClient = (data = {}) => ({
        id: data.id,
        user: data.user,
        created_at: data.created_at,
        updated_at: data.updated_at,
        region: data.region || {},
        culture: data.culture || {},
        mechanical_composition: data.mechanical_composition || {},
        degree_of_soil_moisture: data.degree_of_soil_moisture || {},
        fertilizer_action_year: data.fertilizer_action_year,
        analysis_method: data.analysis_method,
        soil_sampling_depth: data.soil_sampling_depth,
        depth_of_arable_layer: data.depth_of_arable_layer,
        report_n: data.report_n,
        report_p: data.report_p,
        report_k: data.report_k,
        planned_yield: data.planned_yield,
        recommended_n_main: toFixed(data.recommended_n_main, 1),
        recommended_p_main: toFixed(data.recommended_p_main, 1),
        recommended_k_main: toFixed(data.recommended_k_main, 1),
        recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
        recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
        recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
        recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
        recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
        recommended_k_feeding: toFixed(data.recommended_k_feeding, 1)
    })

    distributionToClient = (data = {}) => ({ // eslint-disable-line
        id: data.id,
        related_fertilizers: data.related_fertilizers || [],
        created_at: data.created_at,
        updated_at: data.updated_at,
        recommended_n_main: toFixed(data.recommended_n_main, 1),
        recommended_p_main: toFixed(data.recommended_p_main, 1),
        recommended_k_main: toFixed(data.recommended_k_main, 1),
        recommended_n_sowing: toFixed(data.recommended_n_sowing, 1),
        recommended_p_sowing: toFixed(data.recommended_p_sowing, 1),
        recommended_k_sowing: toFixed(data.recommended_k_sowing, 1),
        recommended_n_feeding: toFixed(data.recommended_n_feeding, 1),
        recommended_p_feeding: toFixed(data.recommended_p_feeding, 1),
        recommended_k_feeding: toFixed(data.recommended_k_feeding, 1),
        actual_n_main: toFixed(data.actual_n_main, 1),
        actual_p_main: toFixed(data.actual_p_main, 1),
        actual_k_main: toFixed(data.actual_k_main, 1),
        actual_n_sowing: toFixed(data.actual_n_sowing, 1),
        actual_p_sowing: toFixed(data.actual_p_sowing, 1),
        actual_k_sowing: toFixed(data.actual_k_sowing, 1),
        actual_n_feeding: toFixed(data.actual_n_feeding, 1),
        actual_p_feeding: toFixed(data.actual_p_feeding, 1),
        actual_k_feeding: toFixed(data.actual_k_feeding, 1),
        actual_n_total: toFixed(data.actual_n_total, 1),
        actual_p_total: toFixed(data.actual_p_total, 1),
        actual_k_total: toFixed(data.actual_k_total, 1),
        user: data.user
    })

    list2Client = (items = []) => items.map(this._server2Client);
}

export default new Converter();