import * as constants from './constants';


export const login = payload => ({
    type: constants.login.toString(),
    payload
});

export const register = payload => ({
    type: constants.register.toString(),
    payload
});

export const getProfile = payload => ({
    type: constants.getProfile.toString(),
    payload
});

export const verify = payload => ({
    type: constants.verify.toString(),
    payload
});

export const refresh = payload => ({
    type: constants.refresh.toString(),
    payload
});

export const getProfileInfo = payload => ({
    type: constants.getProfileInfo.toString(),
    payload
});

export const userActivation = payload => ({
    type: constants.userActivation.toString(),
    payload
});

export const resetPassword = payload => ({
    type: constants.resetPassword.toString(),
    payload
});

export const resetPasswordConfirm = payload => ({
    type: constants.resetPasswordConfirm.toString(),
    payload
});

export const updateProfile = payload => ({
    type: constants.updateProfile.toString(),
    payload
});

export const logout = () => ({ type: 'LOGOUT' });
