import ConverterClass from '../../utils/converter';

class Converter extends ConverterClass {
    regions2Client = (data) => data.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) return 1;
        return 0;
    });
}

export default new Converter();