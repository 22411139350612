import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import converter from './converter';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getRegions.toString(),
            defaultSaga({
                constants: constants.getRegions,
                api: api.getRegions
            })
        ),
        yield takeLatest(
            constants.getRegion.toString(),
            defaultSaga({
                constants: constants.getRegion,
                api: api.getRegion
            })
        )
    ]);
}