/* eslint-env browser */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ru from '../public/translations/ru.json';
import en from '../public/translations/en.json';

const translates = { ...ru, ...en };

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: translates,
        defaultNS: 'translation',
        debug: true,
        returnObjects: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        }
    });

export default i18n;
