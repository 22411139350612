import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import options from '../../options';

import { constants } from '../../models';

const { login, logout } = constants;

export default store => next => (action) => {
    if (events[action.type]) {
        window.location.replace(events[action.type]);
    }
    if (action && action.payload && typeof action.payload === 'object') {
        const { response = {} } = action.payload;
        const { status } = response;
        switch (status) {
            case 401: {
                if (location.pathname !== '/') {
                    window.location.replace('/');
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    next(action);
};

const events = {
    [login.success]: '/fields',
    [logout.toString()]: '/'
};
