import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Form, Input, Row, Select
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { getUser } from '../../models/account/selectors';
import { getRegionsCatalog } from '../../models/regions/selectors';
import { resetPassword, updateProfile } from '../../models/account/actions';

const { Password } = Input;

const ProfilePassword = ({ t, cn }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => getUser(state))
    const regions = useSelector(state => getRegionsCatalog(state))
    const [form] = useForm()

    useEffect(() => {
        const {
            email,
            region,
            phone,
            about
        } = user;
        form.setFieldsValue({
            email,
            region: region && region.id,
            phone,
            about
        })
    }, [user])

    const onFinish = (values) => {
        values.id = user.id;
        dispatch(resetPassword(values));
    }

    return (
        <Form form={form} onFinish={onFinish}>
            <div className="form-field">
                <div className="form-label">
                    {t('old password')}
                </div>
                <Form.Item
                    name="oldPassword"
                    rules={[{ required: true, message: t('not empty') }]}>
                    <Password placeholder="********" />
                </Form.Item>
            </div>
            <div className="form-field">
                <div className="form-label">
                    {t('new password')}
                </div>
                <Form.Item
                    name="newPassword"
                    rules={[{ required: true, message: t('not empty') }]}>
                    <Password placeholder="********" />
                </Form.Item>
            </div>
            <div className="form-field">
                <div className="form-label">
                    {t('retype password')}
                </div>
                <Form.Item
                    name="retypePassword"
                    rules={[
                        { required: true, message: t('not empty') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error(t('pass match')));
                            }
                        })
                    ]}>
                    <Password placeholder="********" />
                </Form.Item>
            </div>
            <Row>
                <Col span={24} className={cn('button-block')}>
                    <Button type="primary" htmlType="submit" size="large">
                        {t('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ProfilePassword