import { combineReducers } from 'redux';

import calculator from './calculator';
import calculators from './calculators';
import culture from './culture';
import genusOfMicro from './genus-of-micro';
import preceding from './preceding';
import typeOfResearch from './type-of-research';
import loading from './loading';

export default combineReducers({
    calculators,
    calculator,
    culture,
    genusOfMicro,
    preceding,
    typeOfResearch
});
