import React from 'react'
import { useTranslation } from 'react-i18next';

import {
    Form, Row, Col, Input, Select, DatePicker, Button
} from 'antd'
import { useClassName } from '../../utils/cn';

const Research = ({ onClose }) => {
    const cn = useClassName('research')
    const { t } = useTranslation('research')

    const [form] = Form.useForm()

    const onFinish = (values) => {
        onClose()
    }

    const onValuesChange = (value, values) => {

    }

    return (
        <Form form={form} className={cn()}>
            <Row align="middle" justify="space-around">
                <h2 className="modal-h2">
                    {t('title')}
                </h2>
            </Row>
            <div className={cn('inner')}>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('planned culture')}</div>
                            <Form.Item>
                                <Select>
                                    <Select.Option selected>{t('choose from list')}</Select.Option>
                                    <Select.Option value={t('winter wheat')}>{t('winter wheat')}</Select.Option>
                                    <Select.Option value={t('spring wheat')}>{t('spring wheat')}</Select.Option>
                                    <Select.Option value={t('winter rye')}>{t('winter rye')}</Select.Option>
                                    <Select.Option value={t('corn')}>{t('corn')}</Select.Option>
                                    <Select.Option value={t('sunflower')}>{t('sunflower')}</Select.Option>
                                    <Select.Option value={t('peas')}>{t('peas')}</Select.Option>
                                    <Select.Option value={t('spring rape')}>{t('spring rape')}</Select.Option>
                                    <Select.Option value={t('oats')}>{t('oats')}</Select.Option>
                                    <Select.Option value={t('soy')}>{t('soy')}</Select.Option>
                                    <Select.Option value={t('winter rape')}>{t('winter rape')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="primary-bg">
                            {t('culture desc')}
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('ripeness')}</div>
                            <Form.Item>
                                <Select>
                                    <Select.Option selected>{t('choose from list')}</Select.Option>
                                    <Select.Option value={t('early ripe')}>{t('early ripe')}</Select.Option>
                                    <Select.Option value={t('mid-season')}>{t('mid-season')}</Select.Option>
                                    <Select.Option value={t('late ripening')}>{t('late ripening')}</Select.Option>
                                    <Select.Option value={t('mid late')}>{t('mid late')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="primary-bg">
                            {t('ripeness desc')}
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('predecessor')}</div>
                            <Form.Item>
                                <Select>
                                    <Select.Option selected>{t('choose from list')}</Select.Option>
                                    <Select.Option value={t('winter wheat')}>{t('winter wheat')}</Select.Option>
                                    <Select.Option value={t('spring wheat')}>{t('spring wheat')}</Select.Option>
                                    <Select.Option value={t('winter rye')}>{t('winter rye')}</Select.Option>
                                    <Select.Option value={t('corn')}>{t('corn')}</Select.Option>
                                    <Select.Option value={t('sunflower')}>{t('sunflower')}</Select.Option>
                                    <Select.Option value={t('peas')}>{t('peas')}</Select.Option>
                                    <Select.Option value={t('spring rape')}>{t('spring rape')}</Select.Option>
                                    <Select.Option value={t('oats')}>{t('oats')}</Select.Option>
                                    <Select.Option value={t('soy')}>{t('soy')}</Select.Option>
                                    <Select.Option value={t('winter rape')}>{t('winter rape')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="primary-bg">
                            {t('predecessor desc')}
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('sowing date')}</div>
                            <Form.Item>
                                <DatePicker allowClear />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="primary-bg">
                            {t('sowing date desc')}
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('email')}</div>
                            <Form.Item>
                                <Input />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="primary-bg">
                            {t('email desc')}
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('name')}</div>
                            <Form.Item>
                                <Input />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14} />
                </Row>
                <Row gutter={20} align="bottom">
                    <Col span={10}>
                        <div className="form-field">
                            <div className="form-label">{t('phone')}</div>
                            <Form.Item>
                                <Input />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={14} />
                </Row>
            </div>
            <Row gutter={20} align="middle" justify="space-around">
                <Button type="primary" htmlTYpe="submit" size="large">
                    {t('research')}
                </Button>
            </Row>
            <Row gutter={20} align="middle" justify="space-around">
                <span className={cn('md-text')}>{t('research required')}</span>
            </Row>
            <Row gutter={20} align="middle" justify="space-around">
                <span className={cn('sm-text')}>{t('info')}</span>
            </Row>
        </Form>
    )
}

export default Research