import {
    put, call, all, takeEvery, takeLatest, select, fork
} from 'redux-saga/effects';
import { getToken } from './account/selectors';
import serverErrorsLogger from '../utils/server-errors-logger';

const action = (name, body, subtype) => ({ type: name, payload: body, subtype });

export const getAction = (name, subtype) => (body => action(name, body, subtype));

export const getActions = (start, success, failed, subtype) => [
    body => action(start, body, subtype),
    body => action(success, body, subtype),
    body => action(failed, body, subtype)
];

export const getActionsByConstant = (constant, subtype) => [
    body => action(constant.try, body, subtype),
    body => action(constant.success, body, subtype),
    body => action(constant.error, body, subtype)
];

/**
 * Получение URL с опциональным параметром id
 * @param url
 * @param args
 * @return {string}
 */
export const getUrl = (url, args) => `${url}${args.length ? `(${args.join(',')})` : ''}`;

/**
 * Возвращает конструктор url с замкнутым значение url указанным первым параметром
 * @param url
 * @return {function(*=): string}
 */
export const urlConstructor = url => (...args) => getUrl(url, args);

/**
 *
 * @param constants объект генератора констант
 * @param api функция вызова
 * @param before хук с payload в качестве аргумента
 * @param after хук с результатом выполнения в качестве агрумента
 * @returns {Function}
 */
export const defaultSaga = (
    {
        constants,
        api,
        before = (...values) => (values),
        after = value => (value)
    }
) => function* (
    {
        type, payload, callback = (isSuccess, content) => {}
    }
) {
    const callbackFun = (isSuccess, body) => {
        setTimeout(callback.bind(null, isSuccess, body), 0);
    };
    const [start, success, failure] = getActionsByConstant(constants);

    const token = yield select(getToken);

    try {
        yield put(start(payload));
        const attributes = before(payload);
        attributes.token = token;
        let response;
        if (Array.isArray(attributes)) { response = yield call(api, ...attributes); } else {
            response = yield call(api, attributes);
        }
        const result = after(response.data);
        yield put(success(result));
        callbackFun(true, result);
    } catch (error) {
        serverErrorsLogger(error);
        yield put(failure(error));
        callbackFun(false, error);
    }
};

/**
 * Получение набора констант для ассинхронной функции
 * @param name
 * @returns {{success: string, try: string, toString: (function(): *), error: string}}
 */
export const getConstant = name => ({
    try: `${name}_TRY`,
    success: `${name}_SUCCESS`,
    error: `${name}_ERROR`,
    toString: () => name
});