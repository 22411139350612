import React from 'react'
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';

import { Line } from 'react-chartjs-2'

import { options } from './config'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default ({ data }) => {
    const dataLine = {
        datasets: [
            {
                data,
                borderColor: 'rgba(61, 139, 253, 1)',
                pointRadius: 10,
                pointBorderColor: 'rgba(31, 205, 117, 1)',
                pointBackgroundColor: 'rgba(31, 205, 117, 1)'
            }
        ]
    }

    return (
        <Line data={dataLine} options={options} />
    )
}