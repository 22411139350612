import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import {
    Form, Input, Select, DatePicker, Button, Row, Col
} from 'antd'

import moment from 'moment';

import { useParams } from 'react-router-dom';
import { useClassName } from '../../utils/cn';
import { getCurField, getCurFieldForecast, getCurPhases } from '../../models/fields/selectors';
import { calculatePestProbability, getField } from '../../models/fields/actions';

import Line from '../forecast-chart'

import { PROBABILITY } from '../constants';

const Forecast = () => {
    const cn = useClassName('forecast')
    const { t } = useTranslation('forecast')
    const dispatch = useDispatch()
    const forecast = useSelector(state => getCurFieldForecast(state))
    const forecastPhases = useSelector(state => getCurPhases(state))
    const field = useSelector(state => getCurField(state))
    const { id } = useParams()
    const [dataLine, setDataLine] = useState({ datasets: [] })

    const [form] = Form.useForm();

    const [curPhase, setPhase] = useState({});

    useEffect(() => {
        if (id) {
            dispatch(getField(id))
        }
    }, [id])

    useEffect(() => {
        const data = forecastPhases?.vegetation_phases?.map(item => ({
            x: item.phase_name, y: moment(item.occurrence_date).valueOf()
        })) || []
        setDataLine([...data])
    }, [forecastPhases])

    useEffect(() => {
        const { items } = forecast
        const date = form.getFieldValue('phase') || moment(Date.now()).format('YYYY-MM-DD');
        let phase = ''
        if (items?.length > 0) {
            phase = items.find((item, i) => (item.occurrence_date <= date
                && (!items[i + 1] || items[i + 1].occurrence_date > date)))

            form.setFieldsValue({
                phase: phase.occurrence_date,
                date: moment(phase.occurrence_date, 'YYYY-MM-DD')
            })
            setPhase({ ...phase });
        }
    }, [forecast]);

    const onFinish = (values) => {
        dispatch(calculatePestProbability(
            {
                id: field.vega_key,
                body: {
                    ...values,
                    culture_id: field?.culture?.id,
                    sowing_date: moment(field.sowing_date).format('YYYY-MM-DD'),
                    date: moment(values.date).format('YYYY-MM-DD')
                }
            }
        ));
    };

    const onValuesChange = (value, values) => {
        const { vegetation_phases } = forecast
        if (Object.keys(value)[0] === 'phase') {
            form.setFieldsValue({ date: moment(values.phase, 'YYYY-MM-DD') })
        }
        if (Object.keys(value)[0] === 'date') {
            const date = moment(values.date).format('YYYY-MM-DD');

            const phase = vegetation_phases.find((item, i) => (item.occurrence_date <= date
                && (!vegetation_phases[i + 1] || vegetation_phases[i + 1].occurrence_date > date)))

            form.setFieldsValue({ phase: phase.occurrence_date })
        }
    }

    return (
        <div className={cn()}>
            <p>{t('forecast title')}</p>
            <div>
                {dataLine.length > 0 && <Line data={dataLine} />}
            </div>
            <Form form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}>
                <div className={cn('form')}>
                    <Row gutter={30}>
                        <Col span={12}>
                            <div className="form-field">
                                <div className="form-label">
                                    {t('choose phase')}
                                </div>
                                <Form.Item
                                    name="phase"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Select>
                                        {forecastPhases?.vegetation_phases?.map(item => (
                                            <Select.Option value={item.occurrence_date}>{item.phase_name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-field">
                                <div className="form-label">
                                    {t('choose date')}
                                </div>
                                <Form.Item
                                    name="date"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <DatePicker />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col span={12}>
                            <div className="form-field">
                                <div className="form-label">
                                    {t('h air')}
                                </div>
                                <Form.Item
                                    name="h_air"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Input />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-field">
                                <div className="form-label">
                                    {t('h soil')}
                                </div>
                                <Form.Item
                                    name="h_soil"
                                    rules={[{ required: true, message: t('not empty') }]}>
                                    <Input />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-around">
                        <Col>
                            <Button type="primary" htmlType="submit" size="large">
                                {t('calculate')}
                            </Button>
                        </Col>
                    </Row>
                </div>
                {curPhase?.pests?.length > 0 && (
                    <div className="row-table" style={{ marginTop: '30px' }}>
                        <Row className="row-head" gutter={20}>
                            <Col span={4} className="row-table__label">{t('name')}</Col>
                            <Col span={4} className="row-table__description">{t('pathogen')}</Col>
                            <Col span={5} className="row-table__description">{t('epv')}</Col>
                            <Col span={5} className="row-table__description">{t('probability')}</Col>
                            <Col span={6} className="row-table__description">{t('active substance')}</Col>
                        </Row>
                        {
                            curPhase.pests.map((pest, i) => (
                                <Row className="row-table__withborder" key={`${pest.name}${pest.main_drugs}`} gutter={20}>
                                    <Col span={4} className="row-table__label">
                                        {pest.name}
                                    </Col>
                                    <Col span={4} className="row-table__description">
                                        {pest.pathogen}
                                    </Col>
                                    <Col span={5} className="row-table__description">
                                        {pest.economic_harmfulness}
                                    </Col>
                                    <Col span={5} className={`row-table__description ${pest.pest_probability && cn(`phase-color-${PROBABILITY[pest.pest_probability]}`)}`}>
                                        {
                                            pest.pest_probability
                                                ? t(PROBABILITY[pest.pest_probability])
                                                : pest.occurrence_probability
                                        }
                                    </Col>
                                    <Col span={6} className="row-table__description" style={{ whiteSpace: 'pre-wrap' }}>
                                        {pest.main_drugs?.join('\n')}
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                )}
            </Form>
        </div>
    )
}

export default Forecast