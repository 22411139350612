import { getConstant } from '../utils';

export const getCalculatorsMyco = getConstant('GET_CALCULATORS_MYCO');
export const getCalculatorMyco = getConstant('GET_CALCULATOR_MYCO');
export const clearCalculatorMyco = getConstant('CLEAR_CALCULATOR_MYCO');
export const addCalculatorMyco = getConstant('ADD_CALCULATOR_MYCO');
export const closeCalculatorMyco = getConstant('CLOSE_CALCULATOR_MYCO');
export const removeCalculatorMyco = getConstant('REMOVE_CALCULATOR_MYCO');

export const setParamsCalculatorMyco = getConstant('SET_PARAMS_CALCULATOR_MYCO');
export const getCultureCalculatorsMyco = getConstant('GET_CULTURES_CALCULATOR_MYCO');
export const getPrecedingCalculatorsMyco = getConstant('GET_PRECEDING_CALCULATOR_MYCO');
export const getGenusOfMicroCalculatorsMyco = getConstant('GET_GENUS_OF_MICRO_CALCULATOR_MYCO');
export const getGenusOfMicroCalculatorsMycoItem = getConstant('GET_GENUS_OF_MICRO_ITEM_CALCULATOR_MYCO');
export const getTypeOfResearchCalculatorsMyco = getConstant('GET_TYPE_OF_RESEARCH_CALCULATOR_MYCO');
export const getTypeOfResearchCalculatorsMycoItem = getConstant('GET_TYPE_OF_RESEARCH_ITEM_CALCULATOR_MYCO');

