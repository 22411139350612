import * as constants from './constants';

export const setCheckedFields = (payload) => ({
    type: constants.setCheckedFields,
    payload
});

export const setSelectedField = (payload) => ({
    type: constants.setSelectedField,
    payload
});

export const setSelectedMenu = (payload) => ({
    type: constants.setSelectedMenu,
    payload
});