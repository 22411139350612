import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Layout, Modal, Row } from 'antd';
import { useClassName } from 'utils/cn';

import './style.less';
import LoginForm from '../auth/components/login-form';
import Research from './research';

const { Content } = Layout;

export default () => {
    const cn = useClassName('home');
    const { t, i18n } = useTranslation('home');
    const [lang, setLang] = useState('ru');
    const [visible, setVisible] = useState(false);
    const [research, setResearch] = useState(false);
    const [fond, setFond] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLang(lng);
    };

    const showLoginForm = () => {
        setVisible(true);
    };

    const hideLoginForm = () => {
        setVisible(false);
    };


    const showResearchForm = () => {
        setResearch(true);
    };

    const hideResearchForm = () => {
        setResearch(false);
    };

    const showFond = () => {
        setFond(true);
    };

    const hideFond = () => {
        setFond(false);
    };

    const FondInfo = () => {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Row align="center" style={{ marginTop: '50px' }}>
                    <img src="/images/logo_fsi.svg" />
                </Row>
                <Row align="center" style={{ margin: '50px' }}>
                    <h2>{t('fond main')}</h2>
                </Row>
                <Row gutter={20} style={{ marginBottom: '100px', marginTop: '50px' }}>
                    <Col span={12}>
                        <p>{t('fond left one')}</p>
                        <p style={{ marginTop: "25px" }}>{t('fond left two')}</p>
                        <p style={{ marginTop: "25px" }}>{t('fond left three')}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t('fond right one')}</p>
                        <p style={{ marginTop: "25px" }}>{t('fond right two')}</p>
                        <p style={{ marginTop: "25px" }}>{t('fond right three')}<b><a href="https://fasie.ru/">{t('fond right hyperlink')}</a></b>{t('fond right four')}</p>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <main className={cn()}>
            <section id="title">
                <div className="inner">
                    <div className="language">
                        <span className={`${lang === 'ru' ? 'active' : ''}`} onClick={() => changeLanguage('ru')}>RU</span> /
                        <span className={`${lang === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')}>EN</span>
                        <span onClick={showLoginForm}>{t('login')}</span>
                    </div>
                    <div className="col-50">
                        <h1>
                            {t('title')}
                        </h1>
                        <p>
                            {t('subtitle')}
                        </p>
                        <button type="button"
                            className="btn btn-default"
                            onClick={showResearchForm}>
                            {t('get research free')}
                        </button>
                    </div>
                    <div className="col-50">
                        <img src="/images/img_icon.svg" alt="" />
                    </div>
                </div>
            </section>
            <section id="now">
                <div className="inner mb mt">
                    <h2>{t('current')}</h2>
                </div>
                <div className="inner">
                    <div className="col-50 gradient-bg">
                        <p>
                            {t('current title')}
                        </p>

                        <span>
                            <img src="/images/60.svg" alt="" />
                        </span>
                    </div>
                    <div className="col-50">
                        <ul>
                            <li>
                                {t('current problem 1')}
                            </li>
                            <li>
                                {t('current problem 2')}
                            </li>
                            <li>
                                {t('current problem 3')}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="problem">
                <div className="inner mb mt">
                    <h2>{t('problem')}</h2>
                </div>
                <div className="inner">
                    <div className="col-50">
                        <ul>
                            <li>
                                <b>{t('problem title 1')}</b>
                                <p>
                                    {t('problem desc 1')}
                                </p>
                            </li>
                            <li>
                                <b>
                                    {t('problem title 2')}
                                </b>
                            </li>
                        </ul>
                    </div>
                    <div className="col-50">
                        <ul>
                            <li>
                                <b>
                                    {t('problem title 3')}
                                </b>
                            </li>
                            <li>
                                <b>
                                    {t('problem title 4')}
                                </b>
                                <p>
                                    {t('problem desc 4')}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="solve">
                <div className="inner mt flex-center">
                    <h2>{t('solution')}</h2>
                </div>
                <div className="inner green-text center-align flex-center">
                    {t('solution desc')}
                </div>
                <div className="inner flex-center">
                    <div className="col-50">
                        <ul>
                            <li>
                                <img src="/images/icon_weather-forecast_circle.svg" alt="" />
                                {t('solution 1')}
                            </li>
                            <li>
                                <img src="/images/icon_plant_circle.svg" alt="" />
                                {t('solution 2')}
                            </li>
                            <li>
                                <img src="/images/icon_growth-chart_circle.svg" alt="" />
                                {t('solution 3')}
                            </li>
                            <li>
                                <img src="/images/icon_greenhouse_circle.svg" alt="" />
                                {t('solution 4')}
                            </li>
                            <li>
                                <img src="/images/icon_test-tubes_circle.svg" alt="" />
                                {t('solution 5')}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="science">
                <div className="inner mt ">
                    <h2>{t('science')}</h2>
                </div>
                <div className="inner green-text">
                    {t('science desc')}
                </div>
                <div className="inner  flex-center">
                    <div className="col-50">
                        <ul>
                            <li>
                                <img src="/images/01.png" alt="" />
                                <div>
                                    <b>{t('science 1')}</b>
                                    <p>{t('science 1 desc')}</p>
                                </div>

                            </li>
                            <li>
                                <img src="/images/02.png" alt="" />
                                <div>
                                    <b>{t('science 2')}</b>
                                    <p>{t('science 2 desc')}</p>
                                </div>
                            </li>
                            <li>
                                <img src="/images/03.png" alt="" />
                                <div>
                                    <b>{t('science 3')}</b>
                                    <p>{t('science 3 desc')}</p>
                                </div>
                            </li>
                            <li>
                                <img src="/images/04.png" alt="" />
                                <div>
                                    <b>{t('science 4')}</b>
                                    <p>{t('science 4 desc')}
                                    </p>
                                </div>
                            </li>
                            <li>
                                <img src="/images/05.png" alt="" />
                                <div>
                                    <b>{t('science 5')}</b>
                                    <p>{t('science 5 desc')}</p>
                                </div>
                            </li>
                            <li>
                                <img src="/images/06.png" alt="" />
                                <div>
                                    <b>{t('science 6')}</b>
                                    <p>{t('science 6 desc')}
                                    </p>
                                </div>
                            </li>
                            <li>
                                <img src="/images/07.png" alt="" />
                                <div>
                                    <b>{t('science 7')}</b>
                                    <p>
                                        {t('science 7 desc')}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="feature">
                <div className="inner mb">
                    <h2>{t('feature')}</h2>
                </div>
                <div className="inner">
                    <div className="feature-left">
                        <div className="green-text">
                            {t('feature 1')}
                        </div>
                        <img src="/images/pict1.png" alt="" />
                        <div className="green-text">
                            {t('feature 2')}
                        </div>
                        <img src="/images/pict2.png" alt="" />
                    </div>
                    <div className="feature-right">
                        <ul>
                            <li>
                                <b>{t('feature 3')}</b>
                                <p>
                                    {t('feature 3 desc')}
                                </p>
                            </li>
                            <li>
                                <b>{t('feature 4')}</b>
                                <p>
                                    {t('feature 4 desc')}
                                </p>
                            </li>
                            <li>
                                <b>{t('feature 5')}</b>
                                <p>
                                    {t('feature 5 desc')}
                                </p>
                            </li>
                            <li>
                                <b>{t('feature 6')}</b>
                                <p>
                                    {t('feature 6 desc')}
                                </p>
                            </li>
                            <li>
                                <b>{t('feature 7')}</b>
                                <p>
                                    {t('feature 7 desc')}
                                </p>
                            </li>
                            <li>
                                <b>{t('feature 8')}</b>
                                <p>
                                    {t('feature 8 desc')}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="characteristics">
                <div className="inner">
                    <h2>{t('characteristics')}</h2>
                </div>
                <div className="inner green-text">
                    {t('characteristics desc')}
                </div>
                <div className="inner">
                    <table cellPadding="0" cellSpacing="0">
                        {
                            [0, 1, 2, 3, 4].map(item => (
                                <tr key={item}>
                                    <th>{t(`ch 1 td${item}`)}</th>
                                    <th>{t(`ch 2 td${item}`)}</th>
                                    <th>{t(`ch 3 td${item}`)}</th>
                                    <th>{t(`ch 4 td${item}`)}</th>
                                </tr>
                            ))
                        }
                    </table>
                </div>
            </section>
            <section id="info">
                <div className="inner mb mt">
                    <h2>{t('partners')}</h2>
                </div>
                <div className="inner mb flex-wrap-block flex-between">
                    {
                        [1, 2, 3, 4, 5].map(item => (
                            <div className="company">
                                <div className="company-item">
                                    <img src="/images/square.svg" alt="" />
                                    {t(`partners ${item}`)}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="inner mb">
                    <h2>{t('team')}</h2>
                </div>
                <div className="inner mb flex-center">
                    <div className="col-50 flex-wrap-block ">
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                                <div className="person">
                                    <b>{t(`team ${item}`)}</b>
                                    <p>{t(`team ${item} desc`)}</p>
                                </div>
                            ))
                        }

                    </div>
                </div>
                <div className="inner mb">
                    <h2> {t('get research')}</h2>
                </div>
                <div className="inner green-text center-align flex-center">
                    {t('research text 1')}
                </div>
                <div className="inner black-text center-align  flex-center">
                    {t('research text 2')}
                </div>
                <div className="inner mb" style={{ justifyContent: 'center', paddingBottom: '50px' }}>
                    <button type="button"
                        className="btn btn-primary"
                        onClick={showResearchForm}>
                        {t('get research')}
                    </button>
                </div>
                <div className="inner mb" style={{ justifyContent: 'center', paddingBottom: '50px' }}>
                    <div style={{ cursor: 'pointer' }}
                        onClick={showFond}>
                        <img src="/images/logo_fsi.svg" />
                    </div>
                </div>
            </section>
            <Modal
                centered
                style={{ height: '100%', width: '100%' }}
                width="100%"
                footer={null}
                visible={fond}
                className={cn('modal')}
                onCancel={hideFond}>
                <FondInfo />
            </Modal>
            <Modal
                width={480}
                footer={null}
                destroyOnClose
                visible={visible}
                className={cn('modal')}
                onCancel={hideLoginForm}>
                <LoginForm onClose={hideLoginForm} />
            </Modal>
            <Modal
                width={1120}
                footer={null}
                destroyOnClose
                visible={research}
                className={cn('modal')}
                onCancel={hideResearchForm}>
                <Research onClose={hideResearchForm} />
            </Modal>
        </main>
    );
};
