import React from 'react';
import {
    Card, Col, Collapse, Row, Tabs
} from 'antd';

const { TabPane } = Tabs;
const { Panel } = Collapse;

export const FieldsCard = ({
    selectedFields, t, cn, onChangeTabFields
}) => (
    <Card bordered={false}>
        <Tabs onChange={onChangeTabFields} className={cn('sider-tabs')}>
            {
                selectedFields.map(field => (
                    <TabPane tab={field.vega_key} key={field.vega_key}>
                        <Collapse className={cn('sider-collapse')}>
                            <Panel header={t('common info')} key="1">
                                <div>
                                    <Row>
                                        <Col span={12}>{t('selection region')}</Col>
                                        <Col span={12}>{field.region?.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('selection area')}</Col>
                                        <Col span={12}>{field.district}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('OKTMO')}</Col>
                                        <Col span={12}>{field.oktmo}</Col>
                                    </Row>
                                    {field.field_num && (
                                        <Row>
                                            <Col span={12}>{t('field num')}</Col>
                                            <Col span={12}>{field.field_num}</Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col span={12}>{t('agricultural land')}</Col>
                                        <Col span={12}>{field.farmland_type}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('soil type')}</Col>
                                        <Col span={12}>{field.soil_type}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('acidity')}</Col>
                                        <Col span={12}>{field?.ph_salt}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('preceding')}</Col>
                                        <Col span={12}>{field?.preceding_culture?.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('culture')}</Col>
                                        <Col span={12}>{field?.culture?.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('planned yield')}</Col>
                                        <Col span={12}>{field?.planned_yield}</Col>
                                    </Row>
                                    { field.google_disk_url && (
                                        <Row style={{ marginTop: '30px' }}>
                                            <Col span={24}>
                                                <a target="_blank"
                                                    rel="noreferrer noopener"
                                                    href={field.google_disk_url}>
                                                    {t('link to')}
                                                </a>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Panel>
                            <Panel header={t('microelements')} key="2">
                                <div>
                                    {field.no3 && (
                                        <Row>
                                            <Col span={12}>NO<sub>3</sub></Col>
                                            <Col span={12}>{field.no3}</Col>
                                        </Row>
                                    )}
                                    {field.nh4 && (
                                        <Row>
                                            <Col span={12}>NH<sub>4</sub></Col>
                                            <Col span={12}>{field.nh4}</Col>
                                        </Row>
                                    )}
                                    {field.p2o5 && (
                                        <Row>
                                            <Col span={12}>P<sub>2</sub>O<sub>5</sub></Col>
                                            <Col span={12}>{field.p2o5}</Col>
                                        </Row>
                                    )}
                                    {field.k2o && (
                                        <Row>
                                            <Col span={12}>K<sub>2</sub>O</Col>
                                            <Col span={12}>{field.k2o}</Col>
                                        </Row>
                                    )}

                                    {field.s && (
                                        <Row>
                                            <Col span={12}>S</Col>
                                            <Col span={12}>{field.s}</Col>
                                        </Row>
                                    )}
                                    {field.ca && (
                                        <Row>
                                            <Col span={12}>Ca</Col>
                                            <Col span={12}>{field.ca}</Col>
                                        </Row>
                                    )}
                                    {field.mg && (
                                        <Row>
                                            <Col span={12}>Mg</Col>
                                            <Col span={12}>{field.mg}</Col>
                                        </Row>
                                    )}
                                    {field.na && (
                                        <Row>
                                            <Col span={12}>Na</Col>
                                            <Col span={12}>{field.na}</Col>
                                        </Row>
                                    )}
                                    {field.so4 && (
                                        <Row>
                                            <Col span={12}>SO<sub>4</sub></Col>
                                            <Col span={12}>{field.so4}</Col>
                                        </Row>
                                    )}
                                    {field.cl && (
                                        <Row>
                                            <Col span={12}>Cl</Col>
                                            <Col span={12}>{field.cl}</Col>
                                        </Row>
                                    )}
                                    {field.hg && (
                                        <Row>
                                            <Col span={12}>Hg</Col>
                                            <Col span={12}>{field.hg}</Col>
                                        </Row>
                                    )}
                                    {field.v && (
                                        <Row>
                                            <Col span={12}>V</Col>
                                            <Col span={12}>{field.v}</Col>
                                        </Row>
                                    )}
                                    {field.b && (
                                        <Row>
                                            <Col span={12}>B</Col>
                                            <Col span={12}>{field.b}</Col>
                                        </Row>
                                    )}
                                    {field.mn && (
                                        <Row>
                                            <Col span={12}>Mn</Col>
                                            <Col span={12}>{field.mn}</Col>
                                        </Row>
                                    )}
                                    {field.cu && (
                                        <Row>
                                            <Col span={12}>Cu</Col>
                                            <Col span={12}>{field.cu}</Col>
                                        </Row>
                                    )}
                                    {field.mo && (
                                        <Row>
                                            <Col span={12}>Mo</Col>
                                            <Col span={12}>{field.mo}</Col>
                                        </Row>
                                    )}
                                    {field.co && (
                                        <Row>
                                            <Col span={12}>Co</Col>
                                            <Col span={12}>{field.co}</Col>
                                        </Row>
                                    )}
                                    {field.zn && (
                                        <Row>
                                            <Col span={12}>Zn</Col>
                                            <Col span={12}>{field.zn}</Col>
                                        </Row>
                                    )}
                                    {field.pb && (
                                        <Row>
                                            <Col span={12}>Pb</Col>
                                            <Col span={12}>{field.pb}</Col>
                                        </Row>
                                    )}
                                    {field.ni && (
                                        <Row>
                                            <Col span={12}>Ni</Col>
                                            <Col span={12}>{field.ni}</Col>
                                        </Row>
                                    )}
                                </div>
                            </Panel>
                            <Panel header={t('field history')} key="3">
                                <div>
                                    <Row>
                                        <Col span={12}>{t('created')}</Col>
                                        <Col span={12}>{field.created}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>{t('updated')}</Col>
                                        <Col span={12}>{field.updated}</Col>
                                    </Row>
                                </div>
                            </Panel>
                        </Collapse>
                    </TabPane>
                ))
            }
        </Tabs>
    </Card>
)