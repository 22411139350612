import options from './options';

const { name } = options;

export const getFieldsList = state => state.models[name].fields.results;
export const getFieldsCount = state => state.models[name].fields.count;
export const getCurField = state => state.models[name].field;
export const getCurFieldForecast = state => state.models[name].field.forecast;
export const getCurPhases = state => state.models[name].field.forecast_phases;
export const getCurVegaKey = state => state.models[name].field?.vega_key;
export const getCurFieldForecastError = state => state.models[name].field.forecast_error;
export const isFieldLoading = state => state.models[name].loading;

export const getCurShapes = state => state.models[name].shape;
export const getProbabilityData = state => state.models[name].probabilityData;
export const isShapeLoading = state => state.models[name].loading;