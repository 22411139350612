import { setCheckedFields, setSelectedField, setSelectedMenu } from './constants';

const initialState = {
    checkedFields: [],
    selectedMenu: 'current',
    selectedField: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case setCheckedFields:
        {
            return { ...state, checkedFields: [...action.payload] };
        }
        case setSelectedMenu:
        {
            return { ...state, selectedMenu: action.payload };
        }
        case setSelectedField:
        {
            return { ...state, selectedField: action.payload };
        }

        default: return state;
    }
}
