import * as constants from '../constants';
import converter from '../converter';

const initialState = converter._server2Client({});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getField.success: {
            return action.payload;
        }

        case constants.setField: {
            return {
                ...state,
                forecast_phases: { ...action.payload?.forecast },
                vega_key: action.payload?.vega_key,
                culture: action.payload?.culture,
                sowing_date: action.payload?.sowing_date
            };
        }

        case constants.getFieldForecast.success: {
            return { ...state, forecast: { ...action.payload } };
        }

        case constants.updateFieldForecast.success: {
            return { ...state, forecast: { ...action.payload }, forecast_error: false };
        }

        case constants.updateFieldForecast.try: {
            const forecast = { ...state.forecast };
            const culture = { ...forecast.culture };
            culture.id = action.payload?.body?.culture;
            forecast.culture = { ...culture };
            forecast.sowing_date = action.payload?.body?.sowing_date;
            return { ...state, forecast };
        }

        case constants.updateFieldForecast.error: {
            return { ...state, forecast_error: true };
        }

        case constants.clearField.toString(): {
            return initialState;
        }

        default:
            return state;
    }
}
